import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'
import {OwnerState} from '../owner/types'
import {AppState} from '../AppState'
import axios from 'axios'
import {getOwnerInfoFromOwnerState} from '../utils'
import {API_ENDPOINT} from '../../properies'
import {SaJooActionTypes} from './types'
import {
  changeSaJooAnalysis,
  changeSaJooCode,
  changeSaJooLoadingState,
} from './actions'
import {SaJooRecord} from "../records/types";
import {changeSaJooRecords} from "../records/action";
import * as localStorage from 'local-storage';
import {scroller} from "react-scroll/modules";

function* handleFetchSaJooAnalysis() {
  const owner: OwnerState = yield select((appState: AppState) => appState.owner)

  // const localStorage = window.localStorage
  localStorage.set('owner', owner)

  try {
    const ownerInfo = getOwnerInfoFromOwnerState(owner)
    yield put(changeSaJooLoadingState(true))
    const res = yield call(axios.post, `${API_ENDPOINT}/sajoo`, {
      ...ownerInfo,
    })


    const {code, analysis, due} = res.data
    yield put(changeSaJooAnalysis(analysis))
    yield put(changeSaJooCode(code))


    const saJooRecords: SaJooRecord[] = yield select(({records}: AppState) => records.saJooRecords)
    const saJooRecordsNew: SaJooRecord[] = [{
      code,
      due,
      owner: owner
    }, ...saJooRecords].filter(val => val.due > Date.now());
    yield put(changeSaJooRecords(saJooRecordsNew))
    localStorage.set("saJooRecords", saJooRecordsNew)
    scroller.scrollTo('after-load', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeInOutQuart',
    })

  } catch (e) {
    // const errorRes = e.response.data;
    // yield put(changeError(true));
    // yield put(changeErrorType(errorRes.code));
    // yield put(changeErrorMessage(errorRes.message));
    // if (errorRes.code === ErrorType.INVALID_TOKEN) {
    //   yield put(logout());
    // }
    // return;
  } finally {
    yield put(changeSaJooLoadingState(false))
  }
}

function* handleFetchSampleSaJooAnalysis() {

  try {
    yield put(changeSaJooLoadingState(true))
    const res = yield call(axios.get, `${API_ENDPOINT}/sajoo/sample`)


    const {code, analysis, due} = res.data
    yield put(changeSaJooAnalysis(analysis))
    yield put(changeSaJooCode(code))


    scroller.scrollTo('after-load', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeInOutQuart',
    })

  } catch (e) {

  } finally {
    yield put(changeSaJooLoadingState(false))
  }
}

function* watchFetchSaJooAnalysis() {
  yield takeLatest(
    SaJooActionTypes.FETCH_SAJOO_ANALYSIS,
    handleFetchSaJooAnalysis,
  )
}

function* watchFetchSampleSaJooAnalysis() {
  yield takeLatest(
    SaJooActionTypes.FETCH_SAMPLE_SAJOO_ANALYSIS,
    handleFetchSampleSaJooAnalysis,
  )
}

function* sajooSaga() {
  yield all([fork(watchFetchSaJooAnalysis), fork(watchFetchSampleSaJooAnalysis)])
}

export default sajooSaga
