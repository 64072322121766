import Y2050 from './Y2050'
import Y2051 from './Y2051'
import Y2052 from './Y2052'
import Y2053 from './Y2053'
import Y2054 from './Y2054'
import Y2055 from './Y2055'
import Y2056 from './Y2056'
import Y2057 from './Y2057'
import Y2058 from './Y2058'
import Y2059 from './Y2059'

export const Y205 = {
  Y2050: Y2050,
  Y2051: Y2051,
  Y2052: Y2052,
  Y2053: Y2053,
  Y2054: Y2054,
  Y2055: Y2055,
  Y2056: Y2056,
  Y2057: Y2057,
  Y2058: Y2058,
  Y2059: Y2059,
}
