'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

// require('es6-promise/auto')

var _objectAssign = require('object-assign')

var _objectAssign2 = _interopRequireDefault(_objectAssign)

var _analytics = require('./extend/analytics')

var _analytics2 = _interopRequireDefault(_analytics)

var _bootpay_event = require('./extend/bootpay_event')

var _bootpay_event2 = _interopRequireDefault(_bootpay_event)

var _common = require('./extend/common')

var _common2 = _interopRequireDefault(_common)

var _encrypt = require('./extend/encrypt')

var _encrypt2 = _interopRequireDefault(_encrypt)

var _message = require('./extend/message')

var _message2 = _interopRequireDefault(_message)

var _notification = require('./extend/notification')

var _notification2 = _interopRequireDefault(_notification)

var _payment = require('./extend/payment')

var _payment2 = _interopRequireDefault(_payment)

var _platform = require('./extend/platform')

var _platform2 = _interopRequireDefault(_platform)

var _storage = require('./extend/storage')

var _storage2 = _interopRequireDefault(_storage)

var _event = require('./event')

var _event2 = _interopRequireDefault(_event)

require('./style')

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}
var _window = typeof window !== 'undefined' ? window : {}

_window.BootPay = {
  VISIT_TIMEOUT: 86400000, // 재 방문 시간에 대한 interval
  SK_TIMEOUT: 1800000, // 30분
  PAYMENT_LOCK: false,
  CONFIRM_LOCK: false,
  applicationId: void 0,
  version: '3.3.2',
  mode: 'production',
  backgroundId: 'bootpay-background-window',
  windowId: 'bootpay-payment-window',
  iframeId: 'bootpay-payment-iframe',
  closeId: 'bootpay-progress-button-window',
  popupWatchInstance: void 0,
  popupInstance: void 0,
  popupData: void 0,
  ieMinVersion: 9,
  deviceType: 1,
  isSetQuickPopup: false,
  ableDeviceTypes: {
    JS: 1,
    ANDROID: 2,
    IOS: 3,
  },
  methods: {},
  params: {},
  option: {},
  phoneRegex: /^\d{2,3}\d{3,4}\d{4}$/,
  dateFormat: /(\d{4})-(\d{2})-(\d{2})/,
  zeroPaymentMethod: [
    'bankalarm',
    'auth',
    'card_rebill',
    'digital_card_rebill',
  ],
  urls: require('../package.json').urls,
  tk: void 0,
  localStorage: {},
  initialize: function initialize() {
    var logLevel =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1

    if (typeof Element !== 'undefined' && Element !== null) {
      _event2.default.startEventBinding()
      this.setLogLevel(logLevel)
      this.setReadyUUID()
      this.setReadySessionKey()
      return this.bindBootpayCommonEvent()
    }
  },
} // Generated by CoffeeScript 2.5.1
;(0, _objectAssign2.default)(
  _window.BootPay,
  _analytics2.default,
  _bootpay_event2.default,
  _common2.default,
  _encrypt2.default,
  _message2.default,
  _notification2.default,
  _payment2.default,
  _platform2.default,
  _storage2.default,
)

_window.BootPay.initialize()

exports.default = _window.BootPay
