import React from 'react'
import {Dispatch} from 'redux'
import {changeMobileMenuOpen} from '../../store/common/actions'
import {connect} from 'react-redux'
import {
  mobileMenuBox,
  exitIconContainer,
  exitIcon,
  logo,
  comment,
  menuContents,
  menu,
  innerMenu,
  noMenu,
} from './mobileMenu.module.scss'
import {AppState} from '../../store/AppState'
import {Link} from 'gatsby'

interface Props {
  mobileMenuOpen: boolean
  closeMobileMenu: () => void
  // loggedIn: boolean
}

const MobileMenuContainer: React.FC<Props> = ({
                                                mobileMenuOpen,
                                                closeMobileMenu,
                                                // loggedIn,
                                              }) => {
  if (mobileMenuOpen) {
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-white p-8 z-50">
        <div className="w-full flex justify-end items-center h-5 mb-2">
          <img
            className="h-full"
            src={'/icons/x.svg'}
            onClick={() => closeMobileMenu()}
          />
        </div>

        <Link to={'/'}>
          <div className="mb-8">
            <div className="text-3xl font-bold text-gray-800" onClick={() => closeMobileMenu()}>
              에잇코즈
            </div>
            <div className="font-bold text-gray-400 text-xs">최고의 사주분석 어플리케이션</div>
          </div>
        </Link>

        <Link to={'/sajoo'}>

          <div className="mb-6 -mx-4 w-full pl-4 border-l-8 border-blue-600">
            <div className="text-xl font-bold text-gray-800" onClick={() => closeMobileMenu()}>
              사주 풀이
            </div>
            <div className="font-bold text-gray-400 text-xs">전문 명리 분석 엔진을 이용한 사주 풀이 서비스</div>
          </div>
        </Link>


        <Link to={'/today'}>
          <div className="mb-6 -mx-4 w-full pl-4 border-l-8 border-blue-600">
            <div className="text-xl font-bold text-gray-800" onClick={() => closeMobileMenu()}>
              오늘의 운세
            </div>
            <div className="font-bold text-gray-400 text-xs">재미로 보는 오늘의 운세(무료)</div>
          </div>

        </Link>


        <Link to={'/pick-day'}>
          <div className="mb-6 -mx-4 w-full pl-4 border-l-8 border-blue-600">
            <div className="text-xl font-bold text-gray-800" onClick={() => closeMobileMenu()}>
              일반 택일
            </div>
            <div className="font-bold text-gray-400 text-xs">이사, 창업 등 길일 택일 서비스(무료)</div>
          </div>
        </Link>
        <Link to={'/pick-birthday'}>
          <div className="mb-6 -mx-4 w-full pl-4 border-l-8 border-blue-600">
            <div className="text-xl font-bold text-gray-800" onClick={() => closeMobileMenu()}>
              출산 택일
            </div>
            <div className="font-bold text-gray-400 text-xs">좋은 우리 아이 출산 택일 서비스(무료)</div>
          </div>
        </Link>


        <Link to="/replay">
          <div className="mb-6 -mx-4 w-full pl-4 border-l-8 border-blue-600">
            <div className="text-xl font-bold text-gray-800" onClick={() => closeMobileMenu()}>
              다시 보기
            </div>
            <div className="font-bold text-gray-400 text-xs">구매한 내용 다시보기 서비스</div>
          </div>
        </Link>
      </div>
    )
  } else {
    return <div></div>
  }
}

const mapStateToProps = (state: AppState) => ({
  mobileMenuOpen: state.common.mobileMenuOpen,
  // loggedIn: state.login.loggedIn,
})
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeMobileMenu: () => {
    dispatch(changeMobileMenuOpen(false))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuContainer)
