import Y1980 from './Y1980'
import Y1981 from './Y1981'
import Y1982 from './Y1982'
import Y1983 from './Y1983'
import Y1984 from './Y1984'
import Y1985 from './Y1985'
import Y1986 from './Y1986'
import Y1987 from './Y1987'
import Y1988 from './Y1988'
import Y1989 from './Y1989'

export const Y198 = {
  Y1980: Y1980,
  Y1981: Y1981,
  Y1982: Y1982,
  Y1983: Y1983,
  Y1984: Y1984,
  Y1985: Y1985,
  Y1986: Y1986,
  Y1987: Y1987,
  Y1988: Y1988,
  Y1989: Y1989,
}
