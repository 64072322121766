import Y1970 from './Y1970'
import Y1971 from './Y1971'
import Y1972 from './Y1972'
import Y1973 from './Y1973'
import Y1974 from './Y1974'
import Y1975 from './Y1975'
import Y1976 from './Y1976'
import Y1977 from './Y1977'
import Y1978 from './Y1978'
import Y1979 from './Y1979'

export const Y197 = {
  Y1970: Y1970,
  Y1971: Y1971,
  Y1972: Y1972,
  Y1973: Y1973,
  Y1974: Y1974,
  Y1975: Y1975,
  Y1976: Y1976,
  Y1977: Y1977,
  Y1978: Y1978,
  Y1979: Y1979,
}
