import Y1990 from './Y1990'
import Y1991 from './Y1991'
import Y1992 from './Y1992'
import Y1993 from './Y1993'
import Y1994 from './Y1994'
import Y1995 from './Y1995'
import Y1996 from './Y1996'
import Y1997 from './Y1997'
import Y1998 from './Y1998'
import Y1999 from './Y1999'

export const Y199 = {
  Y1990: Y1990,
  Y1991: Y1991,
  Y1992: Y1992,
  Y1993: Y1993,
  Y1994: Y1994,
  Y1995: Y1995,
  Y1996: Y1996,
  Y1997: Y1997,
  Y1998: Y1998,
  Y1999: Y1999,
}
