import {action} from 'typesafe-actions'
import {PickDayActionTypes} from './types'
import {DaySuccintInfos} from '../../types/pickDay/DaySuccintInfos'
import {GoodDay} from '../../types/pickDay/GoodDay'

export const fetchPickBirthDayInfos = () =>
  action(PickDayActionTypes.FETCH_PICK_BIRTH_DAY_INFOS)
export const changePickBirthDayInfo = (infos: DaySuccintInfos[]) =>
  action(PickDayActionTypes.CHANGE_PICK_BIRTH_DAY_INFOS, {infos})
export const changePickDayIsLoading = (isLoading: boolean) =>
  action(PickDayActionTypes.CHANGE_PICK_DAY_IS_LOADING, {isLoading})

export const fetchGoodDayInfos = () =>
  action(PickDayActionTypes.FETCH_GOOD_DAY_INFOS)
export const changeGoodDayInfos = (infos: GoodDay[]) =>
  action(PickDayActionTypes.CHANGE_GOOD_DAY_INFOS, {infos})
