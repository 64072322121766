import {SaJooAction, SaJooActionTypes, SaJooState} from './types'

const initialState: SaJooState = {
  isLoading: false,
  saJooAnalysis: undefined,
}

export function sajooReducer(
  state = initialState,
  action: SaJooAction,
): SaJooState {
  switch (action.type) {
    case SaJooActionTypes.CHANGE_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
    case SaJooActionTypes.CHANGE_SAJOO_ANALYSIS:
      return {
        ...state,
        saJooAnalysis: action.payload.saJooAnalysis,
      }
    case SaJooActionTypes.CHANGE_SAJOO_CODE:
      return {
        ...state,
        code: action.payload.code,
      }

    default:
      return state
  }
}
