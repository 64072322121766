import {SaJooAnalysis} from '../../types/saJoo/SaJooAnalysis'
import {ActionType} from 'typesafe-actions'
import * as SaJooActions from './actions'

export enum SaJooActionTypes {
  FETCH_SAJOO_ANALYSIS = '@@sajoo/FETCH_SAJOO_ANALYSIS',
  FETCH_SAMPLE_SAJOO_ANALYSIS = '@@sajoo/FETCH_SAMPLE_SAJOO_ANALYSIS',
  CHANGE_SAJOO_ANALYSIS = '@@sajoo/CHANGE_SAJOO_ANALYSIS',
  CHANGE_LOADING_STATE = '@@sajoo/CHANGE_LOADING_STATE',
  CHANGE_SAJOO_CODE = '@@sajoo/CHANGE_SAJOO_CODE',
}

export interface SaJooState {
  isLoading: boolean
  code?: string // 구매한 사주의 구매 코드
  saJooAnalysis?: SaJooAnalysis
}

export type SaJooAction = ActionType<typeof SaJooActions>
