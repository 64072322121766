'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
// Generated by CoffeeScript 2.5.1
exports.default = {
  progressMessageHide: function progressMessageHide() {
    var pms
    try {
      pms = document.getElementById('bootpay-progress-message')
      pms.style.setProperty('display', 'none')
      document.getElementById('progress-message-text').innerText = ''
      return document
        .getElementById(this.iframeId)
        .removeEventListener('load', this.progressMessageHide)
    } catch (error) {}
  },
  progressMessageShow: function progressMessageShow(msg) {
    var closeButton =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false

    var btn, btnStyle, pms
    pms = document.getElementById('bootpay-progress-message')
    pms.style.setProperty('display', 'block')
    document.getElementById('progress-message-text').innerText = msg
    btn = document.getElementById('__bootpay-popup-close-button__')
    btnStyle = closeButton ? 'block' : 'none'
    return btn.style.setProperty('display', btnStyle)
  },
  showProgressButton: function showProgressButton() {
    var clb
    clb = document.getElementById(this.closeId)
    return clb.style.setProperty('display', 'block')
  },
  hideProgressButton: function hideProgressButton() {
    var clb
    clb = document.getElementById(this.closeId)
    return clb.style.setProperty('display', 'none')
  },
}
