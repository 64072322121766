import {OwnerState} from './owner/types'
import {EumYang} from '../enums/EumYang'
import {getSolarDateFromLunarDate} from '../functions/lunar/utils'

// 음력으로 입력한 경우 양력으로 바꾸어 준다.
export function getOwnerInfoFromOwnerState(owner: OwnerState) {
  if (owner.calendar === EumYang.EUM) {
    const {year, month, day} = getSolarDateFromLunarDate({
      year: owner.year,
      month: owner.month,
      day: owner.day,
      leapMonth: owner.isLeapMonth,
    })

    return {
      sex: owner.sex,
      year,
      month,
      day,
      hour: owner.hour,
      minute: owner.minute,
    }
  } else {
    return {
      sex: owner.sex,
      year: owner.year,
      month: owner.month,
      day: owner.day,
      hour: owner.hour,
      minute: owner.minute,
    }
  }
}
