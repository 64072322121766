import Y1900 from './Y1900'
import Y1901 from './Y1901'
import Y1902 from './Y1902'
import Y1903 from './Y1903'
import Y1904 from './Y1904'
import Y1905 from './Y1905'
import Y1906 from './Y1906'
import Y1907 from './Y1907'
import Y1908 from './Y1908'
import Y1909 from './Y1909'

export const Y190 = {
  Y1900: Y1900,
  Y1901: Y1901,
  Y1902: Y1902,
  Y1903: Y1903,
  Y1904: Y1904,
  Y1905: Y1905,
  Y1906: Y1906,
  Y1907: Y1907,
  Y1908: Y1908,
  Y1909: Y1909,
}
