import Y1910 from './Y1910'
import Y1911 from './Y1911'
import Y1912 from './Y1912'
import Y1913 from './Y1913'
import Y1914 from './Y1914'
import Y1915 from './Y1915'
import Y1916 from './Y1916'
import Y1917 from './Y1917'
import Y1918 from './Y1918'
import Y1919 from './Y1919'

export const Y191 = {
  Y1910: Y1910,
  Y1911: Y1911,
  Y1912: Y1912,
  Y1913: Y1913,
  Y1914: Y1914,
  Y1915: Y1915,
  Y1916: Y1916,
  Y1917: Y1917,
  Y1918: Y1918,
  Y1919: Y1919,
}
