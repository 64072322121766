import {OwnerState} from "../owner/types";
import * as RecordsActions from "./action";
import {ActionType} from "typesafe-actions";


export enum RecordsActionTypes {
    CHANGE_SAJOO_RECORDS = "@@records/CHANGE_SAJOO_RECORDS",
    FETCH_SAJOO_RECORD = "@@records/FETCH_SAJOO_RECORD",
}



export interface RecordsState {
    saJooRecords: SaJooRecord[]
}

export interface SaJooRecord {
    code: string
    owner: OwnerState
    due: number
    receipt_url: string
}


export type RecordsAction = ActionType<typeof RecordsActions>;

