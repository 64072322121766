import {action} from 'typesafe-actions'
import {SaJooActionTypes} from './types'
import {SaJooAnalysis} from '../../types/saJoo/SaJooAnalysis'

export const fetchSaJooAnalysis = () =>
  action(SaJooActionTypes.FETCH_SAJOO_ANALYSIS)
export const changeSaJooAnalysis = (saJooAnalysis: SaJooAnalysis) =>
  action(SaJooActionTypes.CHANGE_SAJOO_ANALYSIS, {saJooAnalysis: saJooAnalysis})
export const changeSaJooLoadingState = (isLoading: boolean) =>
  action(SaJooActionTypes.CHANGE_LOADING_STATE, {isLoading})

export const changeSaJooCode = (code: string) =>
  action(SaJooActionTypes.CHANGE_SAJOO_CODE, {code})

export const fetchSampleSaJooAnalysis = () =>
  action(SaJooActionTypes.FETCH_SAMPLE_SAJOO_ANALYSIS);