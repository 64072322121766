import React, {useEffect} from 'react'
import {throttle} from 'lodash'
import {menuLinkInPopup, tooltip, tooltipBox} from './HeadMenuBar.module.scss'
import {Link} from 'gatsby'
import '../../styles/global.scss'
import {AppState} from '../../store/AppState'
import {Dispatch} from 'redux'
import {changeMobileMenuOpen, changeScrollY} from '../../store/common/actions'
import {connect} from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'

interface Props {
    onScroll: (position: number) => void
    openMobileMenu: () => void
    mobileMenuOpen: boolean
    error: boolean
    notification: boolean
    payBoxOpen: boolean
}

const HeadMenuBar: React.FC<Props> = ({
                                          onScroll,
                                          openMobileMenu,
                                          mobileMenuOpen,
                                          error,
                                          notification,
                                          payBoxOpen,
                                      }) => {


    useEffect(() => {
        window.addEventListener(
            'scroll',
            throttle(() => {
                onScroll(window.pageYOffset)
            }, 200),
        )
    }, [])

    useEffect(() => {
        if (mobileMenuOpen || error || notification || payBoxOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [mobileMenuOpen, error, notification, payBoxOpen])

    return (
        <header className="fixed w-full top-0 bg-white shadow subpixel-antialiased z-10">
            <div
                className="container max-w-screen-xl	  flex  flex-row justify-between items-center mx-auto py-4 px-8">
                <div className="flex items-center text-2xl">
                    {/*<Link className={styles.styledLink} to={"/"}>*/}
                    <Link className="text-lg font-black text-gray-700" to={'/'}>
                        에잇코즈
                    </Link>
                </div>
                <div className="flex">
                    <Link
                      className="px-4 text-sm font-medium hidden sm:block"
                      to={'/sajoo'}
                    >
                        사주 풀이
                    </Link>
                    <Link
                        className="px-4 text-sm font-medium hidden sm:block"
                        to={'/today'}
                    >
                        오늘의 운세
                    </Link>

                    <div
                        className={`px-4 text-sm font-medium hidden sm:block text-blue-500 hover:text-blue-700 ${tooltip}`}
                    >
                        택일
                        <div className={tooltipBox}>
                            <Link className={menuLinkInPopup} to={'/pick-day'}>
                                일반 택일 (무료)
                            </Link>
                            <Link className={menuLinkInPopup} to={'/pick-birthday'}>
                                출산 택일 (무료)
                            </Link>
                        </div>
                    </div>


                </div>
                <div
                    className="cursor-pointer sm:hidden block"
                    onClick={() => openMobileMenu()}
                >
                    <MenuIcon fontSize={'large'}/>
                </div>

                <div className="hidden sm:block">
                    <Link to="/replay">
                        <button className="text-sm py-3 px-8 bg-blue-400 hover:bg-blue-500  rounded  text-white">
                            다시 보기
                        </button>
                    </Link>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = ({common,  payment}: AppState) => ({
    scrollY: common.scrollY,
    mobileMenuOpen: common.mobileMenuOpen,
    error: common.error,
    notification: common.notification,
    payBoxOpen: payment.payBoxOpen,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onScroll: (position: number) => dispatch(changeScrollY(position)),
    openMobileMenu: () => dispatch(changeMobileMenuOpen(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeadMenuBar)
