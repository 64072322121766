// export const API_URL = "http://localhost:3000/";
import moment from 'moment-timezone'

// export const API_URL = "http://192.168.0.17:3000/";
export const ONE_YEAR_IN_MILLISECONDS = 31556926000 // Solar year in milliseconds
export const ONE_DAY_IN_MILLISECONDS = 86400000
export const ONE_HOUR_IN_MILLI = 3600000

export const END_OF_1900 = moment.tz('1901-01-01 00:00', 'Asia/Seoul')

export const BOOTPAY_APPLICATION_ID = '5e29a15702f57e002d6eed60'

export const SAJOO_PRICE = 7900
