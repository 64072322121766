import Y2000 from './Y2000'
import Y2001 from './Y2001'
import Y2002 from './Y2002'
import Y2003 from './Y2003'
import Y2004 from './Y2004'
import Y2005 from './Y2005'
import Y2006 from './Y2006'
import Y2007 from './Y2007'
import Y2008 from './Y2008'
import Y2009 from './Y2009'

export const Y200 = {
  Y2000: Y2000,
  Y2001: Y2001,
  Y2002: Y2002,
  Y2003: Y2003,
  Y2004: Y2004,
  Y2005: Y2005,
  Y2006: Y2006,
  Y2007: Y2007,
  Y2008: Y2008,
  Y2009: Y2009,
}
