// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-customer-service-tsx": () => import("./../../../src/pages/customer-service.tsx" /* webpackChunkName: "component---src-pages-customer-service-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-pick-birthday-tsx": () => import("./../../../src/pages/pick-birthday.tsx" /* webpackChunkName: "component---src-pages-pick-birthday-tsx" */),
  "component---src-pages-pick-day-tsx": () => import("./../../../src/pages/pick-day.tsx" /* webpackChunkName: "component---src-pages-pick-day-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-replay-tsx": () => import("./../../../src/pages/replay.tsx" /* webpackChunkName: "component---src-pages-replay-tsx" */),
  "component---src-pages-sajoo-tsx": () => import("./../../../src/pages/sajoo.tsx" /* webpackChunkName: "component---src-pages-sajoo-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-today-tsx": () => import("./../../../src/pages/today.tsx" /* webpackChunkName: "component---src-pages-today-tsx" */)
}

