import Y2010 from './Y2010'
import Y2011 from './Y2011'
import Y2012 from './Y2012'
import Y2013 from './Y2013'
import Y2014 from './Y2014'
import Y2015 from './Y2015'
import Y2016 from './Y2016'
import Y2017 from './Y2017'
import Y2018 from './Y2018'
import Y2019 from './Y2019'

export const Y201 = {
  Y2010: Y2010,
  Y2011: Y2011,
  Y2012: Y2012,
  Y2013: Y2013,
  Y2014: Y2014,
  Y2015: Y2015,
  Y2016: Y2016,
  Y2017: Y2017,
  Y2018: Y2018,
  Y2019: Y2019,
}
