import Y2080 from './Y2080'
import Y2081 from './Y2081'
import Y2082 from './Y2082'
import Y2083 from './Y2083'
import Y2084 from './Y2084'
import Y2085 from './Y2085'
import Y2086 from './Y2086'
import Y2087 from './Y2087'
import Y2088 from './Y2088'
import Y2089 from './Y2089'

export const Y208 = {
  Y2080: Y2080,
  Y2081: Y2081,
  Y2082: Y2082,
  Y2083: Y2083,
  Y2084: Y2084,
  Y2085: Y2085,
  Y2086: Y2086,
  Y2087: Y2087,
  Y2088: Y2088,
  Y2089: Y2089,
}
