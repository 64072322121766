import Y1960 from './Y1960'
import Y1961 from './Y1961'
import Y1962 from './Y1962'
import Y1963 from './Y1963'
import Y1964 from './Y1964'
import Y1965 from './Y1965'
import Y1966 from './Y1966'
import Y1967 from './Y1967'
import Y1968 from './Y1968'
import Y1969 from './Y1969'

export const Y196 = {
  Y1960: Y1960,
  Y1961: Y1961,
  Y1962: Y1962,
  Y1963: Y1963,
  Y1964: Y1964,
  Y1965: Y1965,
  Y1966: Y1966,
  Y1967: Y1967,
  Y1968: Y1968,
  Y1969: Y1969,
}
