'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _logger = require('../logger')

var _logger2 = _interopRequireDefault(_logger)

var _superagent = require('superagent')

var _superagent2 = _interopRequireDefault(_superagent)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}

// Generated by CoffeeScript 2.5.1
exports.default = {
  // 결제 정보를 서버로 전송
  notify: function notify(data) {
    var success =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : void 0

    var _this = this

    var error =
      arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : void 0
    var timeout =
      arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3000

    var ref, ref1, ref2, user
    this.removePaymentWindow(false)
    user = this.getUserData()
    this.applicationId =
      data.application_id != null ? data.application_id : this.applicationId
    this.params = {}
    this.params.device_type = this.deviceType
    if (data.method != null) {
      this.params.method = data.method
    }
    this.params.application_id = this.applicationId
    this.params.name = data.name
    this.params.user_info = data.user_info
    this.params.redirect_url =
      data.redirect_url != null ? data.redirect_url : ''
    this.params.return_url = data.return_url != null ? data.return_url : ''
    this.params.phone = ((ref = data.phone) != null ? ref.length : void 0)
      ? data.phone.replace(/-/g, '')
      : ''
    this.params.uuid = ((ref1 = data.uuid) != null ? ref1.length : void 0)
      ? data.uuid
      : this.getData('uuid')
    this.params.order_id =
      data.order_id != null ? String(data.order_id) : void 0
    this.params.order_info = data.order_info != null ? data.order_info : {} // 네이버페이 order 정보
    this.params.sk = this.getData('sk')
    this.params.time = this.getData('time')
    this.params.price = data.price
    this.params.delivery_price = data.delivery_price
    if (data.format != null) {
      this.params.format = this.option.format
    }
    this.params.params = data.params
    this.params.user_id = user != null ? user.id : void 0
    this.params.bank_account =
      data.bank_account != null ? data.bank_account : void 0
    this.params.bank_name = data.bank_name != null ? data.bank_name : void 0
    this.params.order_unique = data.order_unique != null ? data.order_unique : 0
    if ((ref2 = this.params.items) != null ? ref2.length : void 0) {
      this.integrityItemData()
    }
    this.params.items = data.items
    if (this.params.method == null || !this.params.method !== 'auth') {
      this.integrityParams()
    }
    return _superagent2.default
      .post(
        [this.restUrl(), 'notify?ver=' + this.version + '&format=json'].join(
          '/',
        ),
      )
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      .timeout({
        response: timeout,
        deadline: timeout,
      })
      .send(this.encryptParams(this.params))
      .then(function (res) {
        if (res.status !== 200 || res.body.status !== 200) {
          _logger2.default.error(
            'BOOTPAY MESSAGE: ' +
              res.body.message +
              ' - Application ID\uAC00 \uC81C\uB300\uB85C \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694.',
          )
          if (error != null) {
            return error.apply(_this, [
              'BOOTPAY MESSAGE: ' +
                res.body.message +
                ' - Application ID\uAC00 \uC81C\uB300\uB85C \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694.',
              res.body,
            ])
          }
        } else {
          if (success != null) {
            return success.apply(_this, [res.body.data])
          }
        }
      })
      .catch(function (err) {
        if (error != null) {
          return error.apply(_this, [
            '\uC11C\uBC84 \uC624\uB958\uB85C \uC778\uD574 \uACB0\uC81C\uAC00 \uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4. ' +
              err.message,
          ])
        }
      })
  },
}
