'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
// Generated by CoffeeScript 2.5.1
exports.default = {
  //----------------------------------------------------------
  // UUID가 없을 경우 바로 LocalStorage에 저장한다.
  // Comment by Gosomi
  // Date: 2018-04-29
  //----------------------------------------------------------
  setReadyUUID: function setReadyUUID() {
    var ref
    if (!((ref = this.getData('uuid')) != null ? ref.length : void 0)) {
      return this.setData('uuid', this.generateUUID())
    }
  },
  //----------------------------------------------------------
  // Local Storage에서 데이터를 저장한다.
  // Comment by Gosomi
  // Date: 2018-04-28
  //----------------------------------------------------------
  setData: function setData(key, value) {
    try {
      return window.localStorage.setItem(key, value)
    } catch (error) {
      return (this.localStorage[key] = value)
    }
  },
  //----------------------------------------------------------
  // Local Storage에서 데이터를 가져온다.
  // Comment by Gosomi
  // Date: 2018-04-28
  //----------------------------------------------------------
  getData: function getData(key) {
    try {
      return window.localStorage.getItem(key)
    } catch (error) {
      return this.localStorage[key]
    }
  },
}
