import {PickDayAction, PickDayActionTypes, PickDayState} from './types'
import {Reducer} from 'redux'

const initialState: PickDayState = {
  pickBirthDayInfos: [],
  goodDayInfos: [],
  isLoading: false,
}

export const pickDayReducer: Reducer<PickDayState, PickDayAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PickDayActionTypes.CHANGE_PICK_BIRTH_DAY_INFOS:
      return {
        ...state,
        pickBirthDayInfos: action.payload.infos,
      }

    case PickDayActionTypes.CHANGE_PICK_DAY_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }

    case PickDayActionTypes.CHANGE_GOOD_DAY_INFOS:
      return {
        ...state,
        goodDayInfos: action.payload.infos,
      }

    default:
      return state
  }
}
