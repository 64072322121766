import {Moment} from 'moment'
import {LunarDate} from './LunarYear'
import lunar from './lunar'

export function getLunarDateFromMoment(date: Moment): LunarDate {
  const year = date.tz('Asia/Seoul').format('YYYY')
  let lunarYear = lunar[`Y${year}`]
  if (!lunarYear.checkDateInThisLunarYear(date)) {
    lunarYear = lunar[`Y${parseInt(year) - 1}`]
  }
  return lunarYear.getLunarDate(date)
}

export function getSolarDateFromLunarDate(date: LunarDate): LunarDate {
  const year = date.year
  const lunarYear = lunar[`Y${year}`]
  return lunarYear.getSolarDate(date)
}

export function getLunarDateString(date: LunarDate): string {
  return `${date.year}.${date.month}.${date.day}`
}

export function hasLeapMonth(year: string, month: string): boolean {
  const lunarYear = lunar[`Y${year}`]
  return (
    lunarYear !== undefined &&
    lunarYear.leapMonth !== undefined &&
    lunarYear.leapMonth === parseInt(month)
  )
}

export function getDaysOfLunarMonth(
  year: number,
  month: number,
  isLeapMonth: boolean,
): number {
  const lunarYear = lunar[`Y${year}`]

  if (lunarYear === undefined) {
    return 30
  }

  if (lunarYear.leapMonth !== undefined) {
    if (month < lunarYear.leapMonth) {
      return lunarYear.days[month - 1]
    } else if (month === lunarYear.leapMonth && !isLeapMonth) {
      return lunarYear.days[month - 1]
    } else {
      return lunarYear.days[month]
    }
  } else {
    return lunarYear.days[month - 1]
  }
}
