import Y1950 from './Y1950'
import Y1951 from './Y1951'
import Y1952 from './Y1952'
import Y1953 from './Y1953'
import Y1954 from './Y1954'
import Y1955 from './Y1955'
import Y1956 from './Y1956'
import Y1957 from './Y1957'
import Y1958 from './Y1958'
import Y1959 from './Y1959'

export const Y195 = {
  Y1950: Y1950,
  Y1951: Y1951,
  Y1952: Y1952,
  Y1953: Y1953,
  Y1954: Y1954,
  Y1955: Y1955,
  Y1956: Y1956,
  Y1957: Y1957,
  Y1958: Y1958,
  Y1959: Y1959,
}
