import {ActionType} from 'typesafe-actions'
import * as OwnerActions from '../owner/actions'
import {EumYang} from '../../enums/EumYang'

export enum OwnerActionTypes {
  CHANGE_INPUT_MODE = '@@owner/CHANGE_INPUT_MODE',
  CHANGE_YEAR = '@@owner/CHANGE_YEAR',
  CHANGE_MONTH = '@@owner/CHANGE_MONTH',
  CHANGE_DAY = '@@owner/CHANGE_DAY',
  CHANGE_HOUR = '@@owner/CHANGE_HOUR',
  CHANGE_MINUTE = '@@owner/CHANGE_MINUTE',
  CHANGE_SEX = '@@owner/CHANGE_SEX',
  CHANGE_CALENDAR = '@@owner/CHANGE_CALENDAR',
  CHANGE_YUNJOO = '@@owner/CHANGE_YUNJOO',
  CHANGE_WOLJOO = '@@owner/CHANGE_WOLJOO',
  CHANGE_ILJOO = '@@owner/CHANGE_ILJOO',
  CHANGE_SIJOO = '@@owner/CHANGE_SIJOO',
  CHANGE_IS_VALID = '@@owner/CHANGE_IS_VALID',
  CHANGE_IS_LEAPMONTH = '@@owner/CHANGE_IS_LEAPMONTH',
  CHANGE_FULL_STATE = '@@owner/CHANGE_FULL_STATE',
}

export interface OwnerState {
  sex: EumYang
  calendar: EumYang
  year: string
  month: string
  isLeapMonth: boolean
  day: string
  hour: string
  minute: string
}

export type OwnerAction = ActionType<typeof OwnerActions>
