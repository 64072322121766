'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
// Generated by CoffeeScript 2.5.1
var Logger

exports.default = Logger = {
  logLevel: 1,
  setLogLevel: function setLogLevel(level) {
    switch (level) {
      case 1:
        // production
        return (this.logLevel = 1)
      case 2:
        // Warning
        return (this.logLevel = 2)
      case 3:
        // Info
        return (this.logLevel = 3)
      default:
        return (this.logLevel = 4)
    }
  },
  debug: function debug(msg) {
    if (this.logLevel >= 4) {
      return console.log(msg)
    }
  },
  info: function info(msg) {
    if (this.logLevel >= 3) {
      return console.info(msg)
    }
  },
  warn: function warn(msg) {
    if (this.logLevel >= 2) {
      return console.warn(msg)
    }
  },
  error: function error(msg) {
    if (this.logLevel >= 1) {
      return console.error(msg)
    }
  },
}
