import {LunarYear} from '../../LunarYear'
import moment from 'moment-timezone'

const Y1928 = new LunarYear(
  moment.tz('1928-01-23 00:00', 'Asia/Seoul'),
  [1, 2, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [29, 30, 29, 29, 30, 29, 29, 30, 30, 29, 30, 30, 30],
  2,
)

export default Y1928
