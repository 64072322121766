import React, {useRef, useState} from 'react'
import {X} from 'react-feather'
import {AppState} from '../../store/AppState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {
  changePayBoxOpenState,
  requestPayment,
} from '../../store/payment/actions'
import {OwnerState} from '../../store/owner/types'
import {EumYang} from '../../enums/EumYang'
import {PaymentMethod} from '../../store/payment/types'
import {Link} from "gatsby";

interface Props {
  owner: OwnerState
  price: number
  product: string
  payBoxOpen: boolean
  closePayBox: () => void
  requestPayment: (method: PaymentMethod) => void
}

const PayBox: React.FC<Props> = ({
                                   owner,
                                   payBoxOpen,
                                   closePayBox,
                                   price,
                                   product,
                                   requestPayment,
                                 }) => {

  const [agreement, setAgreement] = useState(false);
  const [attempted, setAttempted] = useState(false);
  return (
    <div
      className={
        'fixed top-0 bottom-0 right-0 left-0 bg-gray-900 bg-opacity-70 z-20 flex flex-row items-center justify-center ' +
        `${payBoxOpen ? 'block' : 'hidden'}`
      }
    >
      <div className="bg-white  max-w-md p-4 rounded-md mx-4">
        <div className="flex justify-between items-center  mb-2">
          <div className="text-xl font-bold">입력 정보 확인</div>
          <button onClick={() => closePayBox()}>
            <X/>
          </button>
        </div>
        <div className="text-md text-gray-700 mb-1">
          {`${owner.sex === EumYang.YANG ? '남성' : '여성'} ${
            owner.calendar === EumYang.YANG ? '양력' : '음력'
            } ${owner.year}년 ${owner.month}월 ${owner.day}일 ${owner.hour}시 ${
            owner.minute
            }분`}
        </div>
        <div className="text-xs text-gray-500 mb-4">
          입력하신 정보를 다시 한번 확인하세요.
          <br/>
          시간은 24시 표기법으로 표시됩니다. 예를 들어 낮 2시는 14시로 표시됩니다.
        </div>
        <div className="text-xs text-blue-500 mb-4">
          결제 즉시 풀이 결과가 제공되며 30일간 다시 보실 수 있습니다.
        </div>
        {/*<div className="flex flex-row-reverse w-full pr-2">*/}
        {/*  <div className="mb-1">상품: {product}</div>*/}
        {/*</div>*/}
        <div className="flex flex-row-reverse w-full pr-2">
          <div className="mb-4 font-bold">결제 금액: {price.toLocaleString()}원</div>
        </div>
        <div className="flex flex-row items-center space mb-4 justify-between">
          <div className="flex flex-row items-center">
            <input checked={agreement} onChange={(e) => setAgreement(e.target.checked)} className="h-5 w-5 bg-blue-400"
                   type="checkbox"/>
            <div className="ml-2 text-xs" onClick={() => {
              setAgreement(!agreement)
            }}>이용 약관 동의
            </div>
          </div>
          {
            !agreement && attempted && (
              <div className="text-xs text-red-600 bold">동의가 필요합니다.</div>
            )
          }
        </div>
        <div className="sm:flex">
          <button
            className="block mx-auto mb-2 sm:mb-0 sm:mr-4 w-full sm:w-44  py-3 font-bold   bg-black hover:bg-gray-900 rounded text-white"
            onClick={() => {
              if (!agreement) {
                setAttempted(true);
                return;
              }
              closePayBox()
              requestPayment(PaymentMethod.CARD)
            }}
          >
            신용카드
          </button>
          <button
            className="block mx-auto  w-full  sm:w-44  py-3 font-bold rounded text-black"
            style={{backgroundColor: '#FEE500'}}
            onClick={() => {
              if (!agreement) {
                setAttempted(true);
                return;
              }
              closePayBox()
              requestPayment(PaymentMethod.KAKAO)
            }}
          >
            카카오페이
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({owner, payment}: AppState) => ({
  owner: owner,
  payBoxOpen: payment.payBoxOpen,
  price: payment.price,
  product: payment.product,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closePayBox: () => dispatch(changePayBoxOpenState(false)),
  requestPayment: (method: PaymentMethod) => dispatch(requestPayment(method)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PayBox)
