import {RecordsAction, RecordsActionTypes, RecordsState, SaJooRecord} from "./types";
import * as localStorage from "local-storage";
import {Reducer} from "redux";


const saJooRecords = localStorage.get<SaJooRecord[]>("saJooRecords");

const initialState: RecordsState = {
    saJooRecords: saJooRecords ? saJooRecords : []
}


export const recordsReducer: Reducer<RecordsState, RecordsAction> = (state = initialState, action) => {
    switch (action.type) {
        case RecordsActionTypes.CHANGE_SAJOO_RECORDS:
            return {
                ...state,
                saJooRecords: action.payload.saJooRecords
            };

        default:
            return state;
    }
}