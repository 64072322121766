'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _logger = require('../logger')

var _logger2 = _interopRequireDefault(_logger)

var _aes = require('crypto-js/aes')

var _aes2 = _interopRequireDefault(_aes)

var _encBase = require('crypto-js/enc-base64')

var _encBase2 = _interopRequireDefault(_encBase)

var _superagent = require('superagent')

var _superagent2 = _interopRequireDefault(_superagent)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}

// Generated by CoffeeScript 2.5.1
exports.default = {
  // Parent 혹은 Opener에서 데이터를 가져와 통계 데이터를 동기화한다.
  setAnalyticsDataByParent: function setAnalyticsDataByParent(parent) {
    return parent.postMessage(
      JSON.stringify({
        action: 'BootpayAnalyticsData',
      }),
      '*',
    )
  },
  // 기본적인 통계 데이터를 설정한다.
  // Android, iPhone에서 기본적으로 사용하는 코드
  setAnalyticsData: function setAnalyticsData(data) {
    if (data.uuid != null) {
      this.setData('uuid', data.uuid)
    }
    if (data.sk != null) {
      this.setData('sk', data.sk)
    }
    if (data.sk_time != null) {
      this.setData('sk_time', data.sk_time)
    }
    if (data.time != null) {
      return this.setData('time', data.time)
    }
  },
  // 로그인한 유저 정보를 가져온다.
  getUserData: function getUserData() {
    try {
      return JSON.parse(this.getData('user'))
    } catch (error) {
      return void 0
    }
  },
  // Javascript로 UUID를 생성한다.
  generateUUID: function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r, v
        r = (Math.random() * 16) | 0
        v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      },
    )
  },
  // 로그인 했을때 데이터를 저장한다.
  setUserData: function setUserData(data) {
    return this.setData('user', JSON.stringify(data))
  },
  // 세션 키를 발급하는 로직
  setReadySessionKey: function setReadySessionKey() {
    var lastTime, ref, ref1, sessionKeyTime
    sessionKeyTime = new Date().getTime()
    if ((ref = this.getData('last_time')) != null ? ref.length : void 0) {
      sessionKeyTime = new Date().getTime()
      if ((ref1 = this.getData('last_time')) != null ? ref1.length : void 0) {
        lastTime = parseInt(this.getData('last_time')) // 마지막으로 접근한 시간을 기록한다.
        this.setData('last_time', sessionKeyTime)
        if (isNaN(lastTime) || lastTime + this.SK_TIMEOUT < sessionKeyTime) {
          // 마지막 접속한 시간에 30분이 지나버린 경우 세션을 초기화한다.
          this.setData('sk', this.getData('uuid') + '-' + sessionKeyTime)
          this.setData('sk_time', sessionKeyTime)
          this.setData('time', sessionKeyTime - lastTime)
          return _logger2.default.debug(
            '\uC2DC\uAC04\uC774 \uC9C0\uB098 \uC138\uC158 \uACE0\uC720 \uAC12 \uC815\uBCF4\uB97C \uC0C8\uB85C \uAC31\uC2E0\uD558\uC600\uC2B5\uB2C8\uB2E4. sk: ' +
              this.getData('sk') +
              ', time: ' +
              this.getData('sk_time'),
          )
        } else {
          return _logger2.default.debug(
            '\uC774\uC804 \uC138\uC158\uC744 \uADF8\uB300\uB85C \uC774\uC6A9\uD569\uB2C8\uB2E4. sk: ' +
              this.getData('sk') +
              ', time: ' +
              this.getData('sk_time'),
          )
        }
      }
    } else {
      this.setData('last_time', sessionKeyTime)
      this.setData('sk', this.getData('uuid') + '-' + sessionKeyTime)
      this.setData('sk_time', sessionKeyTime)
      return _logger2.default.debug(
        '처음 접속하여 세션 고유 값을 설정하였습니다.',
      )
    }
  },
  // 로그인 정보를 절차에 따라 초기화한다.
  expireUserData: function expireUserData() {
    var data
    data = this.getUserData()
    // 데이터가 없거나 접속 한지 하루가 지나면 데이터를 삭제한다.
    if (data != null && data.time + this.VISIT_TIMEOUT < new Date().getTime()) {
      _logger2.default.info('시간이 지나 로그인 유저 정보를 초기화 하였습니다.')
      return this.setData('user', null)
    }
  },
  // 통계 시작
  startTrace: function startTrace() {
    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : void 0

    var ref
    if (!((ref = this.applicationId) != null ? ref.length : void 0)) {
      this.setApplicationId()
    }
    this.expireUserData()
    return this.sendCommonData(data)
  },
  // 통계용 데이터를 부트페이로 전송
  sendCommonData: function sendCommonData(data) {
    var encryptData, items, ref, ref1, requestData, url, user
    url = document.URL
    if (
      !url ||
      (url.search(/g-cdn.bootpay.co.kr/) === -1 &&
        url.search(/bootpay.co.kr/) > -1 &&
        url.search(/app.bootpay.co.kr/) === -1)
    ) {
      return
    }
    user = this.getUserData()
    items =
      data != null && ((ref = data.items) != null ? ref.length : void 0)
        ? data.items
        : [
            {
              cat1: data != null ? data.cat1 : void 0,
              cat2: data != null ? data.cat2 : void 0,
              cat3: data != null ? data.cat3 : void 0,
              item_img: data != null ? data.item_img : void 0,
              item_name: data != null ? data.item_name : void 0,
              unique: data != null ? data.unique : void 0,
              price: data != null ? data.price : void 0,
            },
          ]
    requestData = {
      application_id: this.applicationId,
      uuid: this.getData('uuid'),
      time: this.getData('time'),
      url: data != null && data.url != null ? data.url : document.URL,
      referer:
        ((ref1 = document.referrer) != null ? ref1.length : void 0) &&
        document.referrer.search(new RegExp(window.location.hostname)) === -1
          ? document.referrer
          : '',
      sk: this.getData('sk'),
      user_id: user != null ? user.id : void 0,
      page_type: data != null ? data.type : void 0,
      items: items,
    }
    _logger2.default.debug(
      '\uD65C\uB3D9 \uC815\uBCF4\uB97C \uC11C\uBC84\uB85C \uC804\uC1A1\uD569\uB2C8\uB2E4. data: ' +
        JSON.stringify(requestData),
    )
    encryptData = _aes2.default.encrypt(
      JSON.stringify(requestData),
      requestData.sk,
    )
    return _superagent2.default
      .post([this.analyticsUrl(), 'call?ver=' + this.version].join('/'))
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      .send({
        data: encryptData.ciphertext.toString(_encBase2.default),
        session_key:
          encryptData.key.toString(_encBase2.default) +
          '##' +
          encryptData.iv.toString(_encBase2.default),
      })
      .then(function (res) {
        if (res.status !== 200 || res.body.status !== 200) {
          return _logger2.default.warn(
            'BOOTPAY MESSAGE: ' +
              res.body.message +
              ' - Application ID\uAC00 \uC81C\uB300\uB85C \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694.',
          )
        }
      })
      .catch(function (err) {
        return _logger2.default.warn(
          'BOOTPAY MESSAGE: ' +
            err.body.message +
            ' - Application ID\uAC00 \uC81C\uB300\uB85C \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694.',
        )
      })
  },
  // 로그인 정보를 부트페이 서버로 전송한다.
  startLoginSession: function startLoginSession(data) {
    var e
    try {
      if (data == null) {
        throw '로그인 데이터를 입력해주세요.'
      }
      if (data.id == null) {
        throw '로그인 하는 아이디를 입력해주세요.'
      }
    } catch (error) {
      e = error
      _logger2.default.error(e)
      alert(e)
      throw e
    }
    return this.sendLoginData({
      application_id:
        data.application_id != null ? data.application_id : this.applicationId,
      id: data.id,
      username: data.username,
      birth: data.birth,
      phone: data.phone,
      email: data.email,
      gender: data.gender,
      area:
        data.area != null
          ? String(data.area).match(
              /서울|인천|대구|광주|부산|울산|경기|강원|충청북도|충북|충청남도|충남|전라북도|전북|전라남도|전남|경상북도|경북|경상남도|경남|제주|세종|대전/,
            )
          : void 0,
    })
  },
  // 부트페이 서버로 데이터를 전송한다.
  sendLoginData: function sendLoginData(data) {
    var _this = this

    var encryptData, ref
    if (data == null || document.URL == null) {
      return
    }
    _logger2.default.debug(
      '\uB85C\uADF8\uC778 \uB370\uC774\uD130\uB97C \uC804\uC1A1\uD569\uB2C8\uB2E4. data: ' +
        JSON.stringify(data),
    )
    data.area = ((ref = data.area) != null ? ref.length : void 0)
      ? data.area[0]
      : void 0
    encryptData = _aes2.default.encrypt(
      JSON.stringify(data),
      this.getData('sk'),
    )
    return _superagent2.default
      .post([this.analyticsUrl(), 'login?ver=' + this.version].join('/'))
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      .send({
        data: encryptData.ciphertext.toString(_encBase2.default),
        session_key:
          encryptData.key.toString(_encBase2.default) +
          '##' +
          encryptData.iv.toString(_encBase2.default),
      })
      .then(function (res) {
        var json
        if (res.status !== 200 || res.body.status !== 200) {
          return _logger2.default.warn(
            'BOOTPAY MESSAGE: ' +
              res.body.message +
              ' - Application ID\uAC00 \uC81C\uB300\uB85C \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694.',
          )
        } else {
          json = res.body.data
          return _this.setUserData({
            id: json.user_id,
            time: new Date().getTime(),
          })
        }
      })
      .catch(function (err) {
        return _logger2.default.warn(
          'BOOTPAY MESSAGE: ' +
            err.message +
            ' - Application ID\uAC00 \uC81C\uB300\uB85C \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694.',
        )
      })
  },
}
