// @ts-ignore
import {channel} from 'redux-saga'
import {all, call, fork, put, select, take, takeLatest,} from 'redux-saga/effects'
import {PaymentActionTypes, ProductType} from './types'
import {AppState} from '../AppState'
import {doProcessInOurServer, requestPayment, throwPaymentError,} from './actions'
import {changeError, changeErrorMessage, changeErrorType,} from '../common/actions'
import {BOOTPAY_APPLICATION_ID} from '../../constants'
import BootPay from '../../bootpay-js'
import moment from 'moment-timezone'
import {getOwnerInfoFromOwnerState} from '../utils'
import {changeSaJooAnalysis, changeSaJooCode, changeSaJooLoadingState,} from '../sajoo/actions'
import axios from 'axios'
import {API_ENDPOINT} from '../../properies'
import * as localStorage from "local-storage";
import {SaJooRecord} from "../records/types";
import {changeSaJooRecords} from "../records/action";
import {scroller} from "react-scroll/modules";
import {ErrorType} from "../common/types";

const paymentChannel = channel()

// 부트페이를 통한 카드 결제
function* handleRequestPayment(action: ReturnType<typeof requestPayment>) {
  // 상품명, 결제 방법(신용카드 or 카카오페이), 가격
  const {method} = action.payload
  const {price, product} = yield select(({payment}: AppState) => payment)

  const order_id = moment(new Date().getTime())
    .tz('Asia/Seoul')
    .format('YYMMDDHHmmsss')
  BootPay.request({
    price: `${price}`,
    application_id: BOOTPAY_APPLICATION_ID,
    name: product,
    pg: 'nicepay',
    show_agree_window: 0,
    method: method,
    items: [
      {
        item_name: product,
        qty: 1,
        unique: product,
        price: price,
        cat1: '에잇코즈', // 대표 상품의 카테고리 상, 50글자 이내
        cat2: '사주 풀이', // 대표 상품의 카테고리 중, 50글자 이내
        // cat3: "이용권", // 대표상품의 카테고리 하, 50글자 이내
      },
    ],
    order_id: order_id,
    params: {price: price},
  })
    .error(function (data: any) {
      paymentChannel.put(throwPaymentError(data))
    })
    .cancel(function (data: any) {
      // 사용자가 스스로 결제를 취소한 경우엔 따로 경고 창을 또 띄우지 않는다.
    })
    .confirm(function (data: any) {
      // @ts-ignore
      BootPay.transactionConfirm(data)
    })
    .close(function (data: any) {
      // 사용자가 스스로 결제창을 닫은 경우에는 따로 경고창을 또 띄우지 않는다.
    })
    .done(function (data: any) {
      paymentChannel.put(doProcessInOurServer(data, product))
    })
}

function* handleThrowPaymentError(
  action: ReturnType<typeof throwPaymentError>,
) {
  yield put(changeError(true))
  yield put(changeErrorMessage('결제 도중 오류가 발생하여 정상적으로 결제되지 않았습니다. 불편을 드려서 죄송합니다. 다음에 다시 시도해주시기 바랍니다.'))
}

function* handleDoProcessInOurServer(
  action: ReturnType<typeof doProcessInOurServer>,
) {
  const {data, product} = action.payload

  switch (product) {
    case ProductType.NONE:
      break;
    // 나중에 궁합도 판매하기 위해 미리 케이스 열어 놓음.
    case ProductType.GOONGHAB:
      break;
    case ProductType.SAJOO: {
      const ownerOriginal = yield select(({owner}: AppState) => owner)
      localStorage.set('owner', ownerOriginal)

      try {
        const owner = getOwnerInfoFromOwnerState(ownerOriginal)
        yield put(changeSaJooLoadingState(true))

        // 서버에 결제 정보 및 사주 정보 송신
        const res = yield call(axios.post, `${API_ENDPOINT}/payment/sajoo`, {
          data,
          product,
          owner,
        })

        // 서버로 부터 데이터 수신.
        const {code, analysis, due, receipt_url} = res.data
        yield put(changeSaJooAnalysis(analysis))
        yield put(changeSaJooCode(code))

        scroller.scrollTo('after-load', {
          duration: 800,
          delay: 0,
          offset: -70,
          smooth: 'easeInOutQuart',
        })

        // 로컬에 사주 정보 기록
        const saJooRecords: SaJooRecord[] = yield select(({records}: AppState) => records.saJooRecords)
        const saJooRecordsNew: SaJooRecord[] = [{
          code,
          due,
          owner: ownerOriginal,
          receipt_url
        }, ...saJooRecords].filter(val => val.due > Date.now());
        yield put(changeSaJooRecords(saJooRecordsNew))
        localStorage.set("saJooRecords", saJooRecordsNew)

      } catch (e) {
        const errorRes = e.response.data;
        yield put(changeError(true));
        yield put(changeErrorType(errorRes.code));
        yield put(changeErrorMessage(errorRes.message))
      } finally {
        yield put(changeSaJooLoadingState(false))
      }
      break
    }

    default:
      return
  }
}

function* watchThrowPaymentError() {
  yield takeLatest(
    PaymentActionTypes.THROW_PAYMENT_ERROR,
    handleThrowPaymentError,
  )
}

function* watchDoProcessInOurServer() {
  yield takeLatest(
    PaymentActionTypes.DO_PROCESS_IN_OUR_SERVER,
    handleDoProcessInOurServer,
  )
}

function* watchRequestPayment() {
  yield takeLatest(PaymentActionTypes.REQUEST_PAYMENT, handleRequestPayment)
}

function* watchPaymentChannel() {
  while (true) {
    const action = yield take(paymentChannel)
    yield put(action)
  }
}

function* paymentSaga() {
  yield all([
    fork(watchThrowPaymentError),
    fork(watchRequestPayment),
    fork(watchPaymentChannel),
    // fork(watchCancelPayment),
    fork(watchDoProcessInOurServer),
  ])
}

export default paymentSaga
