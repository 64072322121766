'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _logger = require('../logger')

var _logger2 = _interopRequireDefault(_logger)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}

exports.default = {
  // RestURL 정보
  restUrl: function restUrl() {
    return this.urls.restUrl[this.mode]
  },
  // 클라이언트 URL 정보
  clientUrl: function clientUrl() {
    return this.urls.clientUrl[this.mode]
  },
  // Analytics URL 정보
  analyticsUrl: function analyticsUrl() {
    return this.urls.analyticsUrl[this.mode]
  },
  // meta tag에서 application id를 찾는다.
  setApplicationId: function setApplicationId() {
    var applicationId =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : void 0

    var metaTag
    if (applicationId != null) {
      return (this.applicationId = applicationId)
    } else {
      metaTag = document.querySelector('meta[name="bootpay-application-id"]')
      if (metaTag != null) {
        return (this.applicationId = metaTag.getAttribute('content'))
      } else {
        return alert(
          '<meta name="bootpay-application-id" content="[Application ID를 입력]" /> 다음과 같이 <head>안에 넣어주세요',
        )
      }
    }
  },
  // 로그 레벨을 설정한다.
  setLogLevel: function setLogLevel() {
    var logLevel =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1

    return _logger2.default.setLogLevel(logLevel)
  },
  // 사용할 환경 mode를 설정한다
  setMode: function setMode(mode) {
    return (this.mode = mode)
  },
  // device Type을 설정한다. 없을 경우 false를 리턴, 있는 경우 true를 리턴
  setDevice: function setDevice(deviceType) {
    if (this.ableDeviceTypes[deviceType] != null) {
      this.deviceType = this.ableDeviceTypes[deviceType]
    }
    return this.ableDeviceTypes[deviceType] != null
  },
  cancelMethodCall: function cancelMethodCall() {
    var _this = this

    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    return setTimeout(function () {
      if (_this.methods.cancel != null) {
        return _this.methods.cancel.call(_this, data)
      }
    }, this.timeIntervalByPlatform())
  },
  errorMethodCall: function errorMethodCall() {
    var _this2 = this

    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    return setTimeout(function () {
      if (_this2.methods.error != null) {
        return _this2.methods.error.call(_this2, data)
      }
    }, this.timeIntervalByPlatform())
  },
  confirmMethodCall: function confirmMethodCall() {
    var _this3 = this

    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    return setTimeout(function () {
      if (_this3.methods.confirm != null) {
        return _this3.methods.confirm.call(_this3, data)
      }
    }, this.timeIntervalByPlatform())
  },
  readyMethodCall: function readyMethodCall() {
    var _this4 = this

    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    return setTimeout(function () {
      if (_this4.methods.ready != null) {
        return _this4.methods.ready.call(_this4, data)
      }
    }, this.timeIntervalByPlatform())
  },
  closeMethodCall: function closeMethodCall() {
    var _this5 = this

    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    return setTimeout(function () {
      if (_this5.methods.close != null) {
        return _this5.methods.close.call(_this5, data)
      }
    }, this.timeIntervalByPlatform())
  },
  doneMethodCall: function doneMethodCall() {
    var _this6 = this

    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    return setTimeout(function () {
      if (_this6.methods.done != null) {
        return _this6.methods.done.call(_this6, data)
      }
    }, this.timeIntervalByPlatform())
  },
} // Generated by CoffeeScript 2.5.1
