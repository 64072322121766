import {LunarYear} from '../../LunarYear'
import moment from 'moment-timezone'

const Y2061 = new LunarYear(
  moment.tz('2061-01-22 00:00', 'Asia/Seoul'),
  [1, 2, 3, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [29, 30, 29, 29, 30, 29, 29, 30, 29, 30, 30, 30, 29],
  3,
)

export default Y2061
