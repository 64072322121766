import {Moment} from 'moment'
import {ONE_DAY_IN_MILLISECONDS} from '../../constants'
import moment from 'moment-timezone'

export class LunarYear {
  get months(): number[] {
    return this._months
  }
  get days(): number[] {
    return this._days
  }
  get leapMonth(): number | undefined {
    return this._leapMonth
  }
  private readonly _months: number[]
  private readonly _days: number[]
  private readonly _leapMonth?: number
  private readonly _newYear: Moment // 설날의 양력일.
  constructor(
    newYear: Moment,
    months: number[],
    days: number[],
    leapMonth?: number,
  ) {
    this._months = months
    this._days = days
    this._newYear = newYear
    this._leapMonth = leapMonth
  }

  // date가 이 음력년의 시작 시간 이후인지 리턴한다.
  public checkDateInThisLunarYear(date: Moment): boolean {
    return this._newYear.toDate().getTime() <= date.toDate().getTime()
  }

  // Moment 타입의 date를 받아서 음력 정보를 리턴한다.
  public getLunarDate(date: Moment): LunarDate {
    const diffDays = Math.floor(
      (date.toDate().getTime() - this._newYear.toDate().getTime()) /
        ONE_DAY_IN_MILLISECONDS,
    )
    let day = diffDays + 1 // 1월 1일 부터 날짜가 시작이니까 1을 더해준다.
    let month = 0
    let monthIndex = 0
    for (let i = 0; i < this._months.length; i++) {
      if (day <= this._days[i]) {
        month = this._months[i]
        monthIndex = i
        break
      } else {
        day -= this._days[i]
      }
    }

    // 윤달일 조건은 첫번째 달이 아니어야 하고, 전달이랑 이번달이랑 달수가 똑같아야 한다.
    const leapMonth = monthIndex > 0 && this._months[monthIndex - 1] === month

    return {
      year: this._newYear.format('YYYY'),
      month: month < 10 ? `0${month}` : month.toString(),
      day: day < 10 ? `0${day}` : day.toString(),
      leapMonth: leapMonth,
    }
  }

  // 리턴 타입이 루나 데이트이지만, 윤달정보만 없으면 솔라나 루나나 같으므로....
  // 연도는 무조건 맞게 들어왔다고 가정한다.
  public getSolarDate(date: LunarDate): LunarDate {
    const month = parseInt(date.month)
    let diff = 0
    let index = 0
    for (let i = 0; i < this._months.length; i++) {
      if (this._months[i] < month) {
        diff += this._days[i]
      } else {
        index = i
        break
      }
    }

    if (date.leapMonth) {
      diff += this._days[index]
    }
    diff += parseInt(date.day) - 1
    const solarDate =
      this._newYear.toDate().getTime() + ONE_DAY_IN_MILLISECONDS * diff
    const solarMoment = moment.tz(solarDate, 'Asia/Seoul')
    return {
      year: solarMoment.format('YYYY'),
      month: solarMoment.format('MM'),
      day: solarMoment.format('DD'),
      leapMonth: false,
    }
  }
}

export interface LunarDate {
  year: string
  month: string
  day: string
  leapMonth: boolean
}
