'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _logger = require('../logger')

var _logger2 = _interopRequireDefault(_logger)

var _superagent = require('superagent')

var _superagent2 = _interopRequireDefault(_superagent)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}

// Generated by CoffeeScript 2.5.1
exports.default = {
  // 창이 닫혔을 때 이벤트 처리
  bindBootpayPaymentEvent: function bindBootpayPaymentEvent() {
    var _this = this

    window.off('message.BootpayGlobalEvent')
    return window.on('message.BootpayGlobalEvent', function (e) {
      var backgroundSelector,
        closeSelector,
        data,
        iframeSelector,
        input,
        isClose,
        k,
        ref,
        v
      try {
        data = {}
        if (
          e.data != null &&
          typeof e.data === 'string' &&
          /Bootpay/.test(e.data)
        ) {
          data = JSON.parse(e.data)
        }
        if (data.action != null) {
          data.action = data.action.replace(/Child/g, '')
        }
      } catch (error) {
        e = error
        _logger2.default.error(
          'data: ' + e.data + ', ' + e.message + ' json parse error',
        )
        return
      }
      switch (data.action) {
        // 팝업창으로 결제창 호출 이벤트
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayPopup':
          // iFrame창을 삭제한다.
          _this.popupData = data
          _this.progressMessageHide()
          if (_this.isIE()) {
            return _this.startPopupPaymentWindow(data)
          } else {
            return _this.showPopupButton()
          }
          break
        // 결제창 form submit 방식으로 동작할 때 action
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayFormSubmit':
          ref = data.params
          for (k in ref) {
            v = ref[k]
            input = document.createElement('INPUT')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', k)
            input.value = v
            document.__BOOTPAY_TOP_FORM__.appendChild(input)
          }
          document.__BOOTPAY_TOP_FORM__.action = data.url
          document.__BOOTPAY_TOP_FORM__.acceptCharset = data.charset
          return document.__BOOTPAY_TOP_FORM__.submit()
        // 사용자가 결제창을 취소했을 때 이벤트
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayCancel':
          _this.progressMessageShow('취소중입니다.')
          try {
            _this.clearEnvironment()
            if (_this.methods.cancel != null) {
              _this.cancelMethodCall(data)
            }
          } catch (error) {
            e = error
            _this.sendPaymentStepData({
              step: 'cancel',
              status: -1,
              e: e,
            })
            throw e
          }
          _this.sendPaymentStepData({
            step: 'cancel',
            status: 1,
          })
          return _this.removePaymentWindow()
        // 가상계좌 입금 대기 상태 ( 계좌 발급이 되었을 때 호출 )
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayBankReady':
          try {
            _this.progressMessageHide()
            _this.clearEnvironment(_this.popupInstance != null ? 0 : 1)
            _this.readyMethodCall(data)
          } catch (error) {
            e = error
            _this.sendPaymentStepData({
              step: 'ready',
              status: -1,
              e: e,
            })
            throw e
          }
          return _this.sendPaymentStepData({
            step: 'ready',
            status: 1,
          })
        // 결제 승인 전 action
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayConfirm':
          _this.progressMessageShow('승인중입니다.')
          try {
            _this.clearEnvironment()
            if (_this.methods.confirm == null) {
              _this.transactionConfirm(data)
            } else {
              _this.confirmMethodCall(data)
            }
          } catch (error) {
            e = error
            _this.sendPaymentStepData({
              step: 'confirm',
              status: -1,
              e: e,
            })
            throw e
          }
          return _this.sendPaymentStepData({
            step: 'confirm',
            status: 1,
          })
        // 결제창 resize 이벤트
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayResize':
          iframeSelector = document.getElementById(_this.iframeId)
          backgroundSelector = document.getElementById(_this.backgroundId)
          closeSelector = document.getElementById(_this.closeId)
          if (data.reset) {
            iframeSelector.removeAttribute('style')
            backgroundSelector.removeAttribute('style')
            closeSelector.removeAttribute('style')
            return iframeSelector.setAttribute('scrolling', void 0)
          } else {
            iframeSelector.style.setProperty('max-width', data.width)
            iframeSelector.style.setProperty('width', '100%')
            iframeSelector.style.setProperty('height', data.height)
            iframeSelector.style.setProperty('max-height', data.maxHeight)
            if (data.backgroundColor != null) {
              iframeSelector.style.setProperty(
                'background-color',
                data.backgroundColor,
              )
            }
            if (data.transparentMode === 'true') {
              backgroundSelector.style.setProperty(
                'background-color',
                'transparent',
              )
            }
            if (data.showCloseWindow === 'true') {
              closeSelector.style.setProperty('display', 'block')
            }
            // ie 9이하에서는 overflow 속성을 인식하지 못한다.
            iframeSelector.style.overflow = data.overflow
            if (data.scrolling != null) {
              return iframeSelector.setAttribute('scrolling', data.scrolling)
            }
          }
          break
        // 결제 진행시 오류가 났을 때 호출 되는 action
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayError':
          try {
            _this.clearEnvironment()
            _this.errorMethodCall(data)
          } catch (error) {
            e = error
            _this.sendPaymentStepData({
              step: 'error',
              status: -1,
              msg: e,
            })
            throw e
          }
          _this.sendPaymentStepData({
            step: 'error',
            status: 1,
          })
          return _this.removePaymentWindow()
        // 결제 완료시 호출되는 action
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayDone':
          _this.progressMessageHide()
          try {
            _this.clearEnvironment(data.popup_close)
            _this.doneMethodCall(data)
          } catch (error) {
            e = error
            _this.sendPaymentStepData({
              step: 'done',
              status: -1,
              e: e,
            })
            throw e
          }
          _this.sendPaymentStepData({
            step: 'done',
            status: 1,
          })
          isClose = data.is_done_close != null ? data.is_done_close : true
          if (isClose) {
            return _this.removePaymentWindow()
          }
          break
        // 사용자 혹은 PG에서 창이 닫히는 action
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayClose':
          _this.clearEnvironment()
          _this.progressMessageHide()
          return _this.removePaymentWindow()
        // iFrame 결제창을 app에서 notify 받아 보여준다
        // Comment by Gosomi
        // Date: 2020-06-30
        // @return [undefined]
        case 'BootpayShowPaymentWindow':
          // iframe 창의 결제창을 보여준다
          document
            .getElementById(_this.iframeId)
            .style.removeProperty('display')
          return document
            .getElementById(_this.iframeId)
            .style.setProperty('height', '100%')
      }
    })
  },
  bindBootpayCommonEvent: function bindBootpayCommonEvent() {
    var _this2 = this

    window.off('message.BootpayCommonEvent')
    return window.on('message.BootpayCommonEvent', function (e) {
      var data
      try {
        data = {}
        if (
          e.data != null &&
          typeof e.data === 'string' &&
          /Bootpay/.test(e.data)
        ) {
          data = JSON.parse(e.data)
        }
      } catch (error) {
        e = error
        _logger2.default.debug(
          'data: ' + e.data + ', ' + e.message + ' json parse error',
        )
        return
      }
      switch (data.action) {
        case 'BootpayAnalyticsData':
          return e.source.postMessage(
            JSON.stringify({
              action: 'BootpayAnalyticsReceived',
              uuid: _this2.getData('uuid'),
              sk: _this2.getData('sk'),
              sk_time: _this2.getData('sk_time'),
              time: _this2.getData('time'),
              user: _this2.getData('user'),
            }),
            '*',
          )
        case 'BootpayAnalyticsReceived':
          _logger2.default.debug(
            'receive analytics data: ' + JSON.stringify(data),
          )
          return _this2.setAnalyticsData(data)
      }
    })
  },
  // 비밀번호 인증 관련 event를 받는다
  // Comment by Gosomi
  // Date: 2020-10-10
  // @return [Hash]
  bindEasyEvent: function bindEasyEvent() {
    var _this3 = this

    window.off('message.BootpayEasyEvent')
    return window.on('message.BootpayEasyEvent', function (e) {
      var data
      try {
        data = {}
        if (
          e.data != null &&
          typeof e.data === 'string' &&
          /Bootpay/.test(e.data)
        ) {
          data = JSON.parse(e.data)
        }
        if (data.action != null) {
          data.action = data.action.replace(/Child/g, '')
        }
      } catch (error) {
        e = error
        _logger2.default.error(
          'data: ' + e.data + ', ' + e.message + ' json parse error',
        )
        return
      }
      switch (data.action) {
        case 'BootpayEasyError':
          if (_this3.methods.easyError != null) {
            _this3.methods.easyError(data)
          }
          return _this3.removeVerifyWindow()
        case 'BootpayEasySuccess':
          if (_this3.methods.easySuccess != null) {
            _this3.methods.easySuccess(data)
          }
          return _this3.removeVerifyWindow()
        case 'BootpayEasyCancel':
          if (_this3.methods.easyCancel != null) {
            _this3.methods.easyCancel(data)
          }
          return _this3.removeVerifyWindow()
      }
    })
  },
  // 강제로 창을 닫는다
  // Comment by Gosomi
  // Date: 2020-02-13
  // @return [undefined]
  forceClose: function forceClose() {
    var message =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : void 0

    this.cancelMethodCall({
      action: 'BootpayCancel',
      message: message != null ? message : '사용자에 의한 취소',
    })
    return this.removePaymentWindow()
  },
  timeIntervalByPlatform: function timeIntervalByPlatform() {
    if (this.isMobile()) {
      return 300
    } else {
      return 0
    }
  },
  // 결제창을 삭제한다.
  removePaymentWindow: function removePaymentWindow() {
    var callClose =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true

    var e
    // Payment Lock을 해제한다
    this.setPaymentLock(false)
    this.progressMessageHide()
    document.body.style.removeProperty('bootpay-modal-open')
    try {
      document.body.classList.remove('bootpay-open')
    } catch (error) {
      ;('')
    }
    if (document.getElementById(this.windowId) != null) {
      document.getElementById(this.windowId).outerHTML = ''
    }
    try {
      if (callClose) {
        this.closeMethodCall()
      }
    } catch (error) {
      e = error
      this.sendPaymentStepData({
        step: 'close',
        status: -1,
        e: e,
      })
      throw e
    }
    this.sendPaymentStepData({
      step: 'close',
      status: 1,
    })
    return (this.tk = void 0)
  },
  // 비밀번호 창을 닫는다
  removeVerifyWindow: function removeVerifyWindow() {
    this.progressMessageHide()
    document.body.style.removeProperty('bootpay-modal-open')
    try {
      document.body.classList.remove('bootpay-open')
    } catch (error) {
      ;('')
    }
    if (document.getElementById(this.windowId) != null) {
      return (document.getElementById(this.windowId).outerHTML = '')
    }
  },
  closePopupWithPaymentWindow: function closePopupWithPaymentWindow() {
    if (
      confirm(
        '결제창을 닫게 되면 현재 진행중인 결제가 취소됩니다. 정말로 닫을까요?',
      )
    ) {
      this.clearEnvironment(true)
      return this.removePaymentWindow()
    }
  },
  cancel: function cancel(method) {
    this.methods.cancel = method
    return this
  },
  confirm: function confirm(method) {
    this.methods.confirm = method
    return this
  },
  ready: function ready(method) {
    this.methods.ready = method
    return this
  },
  error: function error(method) {
    this.methods.error = method
    return this
  },
  done: function done(method) {
    this.methods.done = method
    return this
  },
  close: function close(method) {
    this.methods.close = method
    return this
  },
  easyCancel: function easyCancel(method) {
    this.methods.easyCancel = method
    return this
  },
  easySuccess: function easySuccess(method) {
    this.methods.easySuccess = method
    return this
  },
  easyError: function easyError(method) {
    this.methods.easyError = method
    return this
  },
  setConfirmLock: function setConfirmLock(lock) {
    return (this.CONFIRM_LOCK = lock)
  },
  setPaymentLock: function setPaymentLock(lock) {
    return (this.PAYMENT_LOCK = lock)
  },
  isPaymentLock: function isPaymentLock() {
    return this.PAYMENT_LOCK
  },
  isConfirmLock: function isConfirmLock() {
    return this.CONFIRM_LOCK
  },
  // 결제 실행 단계를 로그로 보낸다.
  sendPaymentStepData: function sendPaymentStepData(data) {
    if (this.tk == null || this.applicationId == null) {
      // Transaction key가 없다면 실행할 필요가 없다.
      return
    }
    data.version = this.version
    data.tk = this.tk
    data.application_id = this.applicationId
    if (data.e != null) {
      data.msg = (function () {
        try {
          return data.e.message
        } catch (error) {
          return data.e
        }
      })()
      data.trace = (function () {
        try {
          return data.e.stack
        } catch (error) {
          return void 0
        }
      })()
    }
    return _superagent2.default
      .post([this.analyticsUrl(), 'event'].join('/'))
      .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      .send(this.encryptParams(data))
      .then(function (res) {
        return _logger2.default.debug(
          'BOOTPAY MESSAGE: 결제 이벤트 데이터 정보 전송',
        )
      })
      .catch(function (err) {
        return _logger2.default.error(
          'BOOTPAY MESSAGE: \uACB0\uC81C \uC774\uBCA4\uD2B8 \uB370\uC774\uD130 \uC815\uBCF4 \uC804\uC1A1\uC2E4\uD328 ' +
            JSON.stringify(err),
        )
      })
  },
  // 팝업 watcher를 삭제한다
  // 창이 닫힌다면 팝업창도 강제로 닫는다
  clearEnvironment: function clearEnvironment() {
    var isClose =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1

    if (this.popupWatchInstance != null) {
      clearInterval(this.popupWatchInstance)
    }
    isClose = isClose != null ? isClose : 1
    if (this.popupInstance != null && isClose) {
      this.popupInstance.close()
      return (this.popupInstance = void 0)
    }
  },
  // 팝업 창이 시작될 때 각 이벤트를 binding하고
  // 팝업창을 띄우고나서 팝업이 닫히는지 매번확인한다
  startPopupPaymentWindow: function startPopupPaymentWindow(data) {
    var _this4 = this

    var k, query, ref, v
    if (this.isMobileSafari) {
      window.off('pagehide.bootpayUnload')
      window.on('pagehide.bootpayUnload', function () {
        if (_this4.popupInstance != null) {
          return _this4.popupInstance.close()
        }
      })
    } else {
      window.off('beforeunload.bootpayUnload')
      window.on('beforeunload.bootpayUnload', function () {
        if (_this4.popupInstance != null) {
          return _this4.popupInstance.close()
        }
      })
    }
    document.getElementById(this.iframeId).style.display = 'none'
    this.clearEnvironment()
    this.hideProgressButton()
    this.progressMessageShow('팝업창을 닫으면 종료됩니다.', true)
    query = []
    ref = data.params
    for (k in ref) {
      v = ref[k]
      if (['su', 'pa_id'].indexOf(k) > -1) {
        query.push(k + '=' + v)
      }
    }
    return setTimeout(function () {
      var left, platform, spec, top
      if (_this4.popupInstance != null) {
        _this4.popupInstance.close()
      }
      // 플랫폼에서 설정해야할 정보를 가져온다
      platform = function () {
        try {
          return data.params.pe[this.platformSymbol()]
        } catch (error) {
          return {}
        }
      }.call(_this4)
      left = (function () {
        try {
          if (window.screen.width < platform.width) {
            return 0
          } else {
            return (window.screen.width - platform.width) / 2
          }
        } catch (error) {
          return '100'
        }
      })()
      top = (function () {
        try {
          if (window.screen.height < platform.height) {
            return 0
          } else {
            return (window.screen.height - platform.height) / 2
          }
        } catch (error) {
          return '100'
        }
      })()
      spec =
        platform != null && platform.width != null && platform.width > 0
          ? 'width=' +
            platform.width +
            ',height=' +
            platform.height +
            ',top=' +
            top +
            ',left=' +
            left +
            ',scrollbars=yes,toolbar=no, location=no, directories=no, status=no, menubar=no'
          : ''
      _this4.popupInstance = window.open(
        data.submit_url + '?' + query.join('&'),
        'bootpay_inner_popup_' + new Date().getTime(),
        spec,
      )
      if (_this4.popupInstance == null) {
        return window.postMessage(
          JSON.stringify({
            action: 'BootpayError',
            message:
              '브라우저의 팝업이 차단되어 결제가 중단되었습니다. 브라우저 팝업 허용을 해주세요.',
          }),
          '*',
        )
      }
      // 팝업 창이 닫혔는지 계속해서 찾는다
      return (_this4.popupWatchInstance = setInterval(function () {
        if (_this4.popupInstance.closed) {
          // 창을 닫은 경우
          if (_this4.popupWatchInstance != null) {
            clearInterval(_this4.popupWatchInstance)
          }
          if (_this4.isMobileSafari) {
            window.off('pagehide.bootpayUnload')
          } else {
            window.off('beforeunload.bootpayUnload')
          }
          // IE 인 경우에 팝업이 뜨면 결제가 완료되었는지 데이터를 확인해본다
          if (_this4.isIE()) {
            return _superagent2.default
              .put(
                [_this4.restUrl(), 'confirm', data.params.su + '.json'].join(
                  '/',
                ),
              )
              .set(
                'Content-Type',
                'application/x-www-form-urlencoded; charset=UTF-8',
              )
              .send(
                _this4.encryptParams({
                  application_id: _this4.applicationId,
                  tk: _this4.tk,
                }),
              )
              .then(function (res) {
                if (res.body != null && res.body.code === 0) {
                  return setTimeout(function () {
                    return window.postMessage(
                      JSON.stringify(res.body.data),
                      '*',
                    )
                  }, 300)
                } else {
                  return window.postMessage(
                    JSON.stringify({
                      action: 'BootpayCancel',
                      message: '팝업창을 닫았습니다.',
                    }),
                    '*',
                  )
                }
              })
              .catch(function (err) {
                return window.postMessage(
                  JSON.stringify({
                    action: 'BootpayCancel',
                    message: '팝업창을 닫았습니다.',
                  }),
                  '*',
                )
              })
          } else {
            return window.postMessage(
              JSON.stringify({
                action: 'BootpayCancel',
                message: '팝업창을 닫았습니다.',
              }),
              '*',
            )
          }
        }
      }, 300))
    }, 100)
  },
  showPopupEventProgress: function showPopupEventProgress() {
    var _this5 = this

    if (this.isMobileSafari) {
      window.off('pagehide.bootpayUnload')
      window.on('pagehide.bootpayUnload', function () {
        if (_this5.popupInstance != null) {
          return _this5.popupInstance.close()
        }
      })
    } else {
      window.off('beforeunload.bootpayUnload')
      window.on('beforeunload.bootpayUnload', function () {
        if (_this5.popupInstance != null) {
          return _this5.popupInstance.close()
        }
      })
    }
    this.progressMessageShow('팝업창을 닫으면 종료됩니다.', true)
    return (this.popupWatchInstance = setInterval(function () {
      if (_this5.popupInstance.closed) {
        // 창을 닫은 경우
        if (_this5.popupWatchInstance != null) {
          clearInterval(_this5.popupWatchInstance)
        }
        if (_this5.isMobileSafari) {
          window.off('pagehide.bootpayUnload')
        } else {
          window.off('beforeunload.bootpayUnload')
        }
        // IE 인 경우에 팝업이 뜨면 결제가 완료되었는지 데이터를 확인해본다
        if (_this5.isIE() && _this5.params.tk != null) {
          return _superagent2.default
            .put([_this5.restUrl(), 'confirm', _this5.tk + '.json'].join('/'))
            .set(
              'Content-Type',
              'application/x-www-form-urlencoded; charset=UTF-8',
            )
            .send(
              _this5.encryptParams({
                application_id: _this5.applicationId,
                method: 'transaction_key',
                tk: _this5.tk,
              }),
            )
            .then(function (res) {
              if (res.body != null && res.body.code === 0) {
                return setTimeout(function () {
                  return window.postMessage(JSON.stringify(res.body.data), '*')
                }, 300)
              } else {
                return window.postMessage(
                  JSON.stringify({
                    action: 'BootpayCancel',
                    message: '팝업창을 닫았습니다.',
                  }),
                  '*',
                )
              }
            })
            .catch(function (err) {
              return window.postMessage(
                JSON.stringify({
                  action: 'BootpayCancel',
                  message: '팝업창을 닫았습니다.',
                }),
                '*',
              )
            })
        } else {
          return window.postMessage(
            JSON.stringify({
              action: 'BootpayCancel',
              message: '팝업창을 닫았습니다.',
            }),
            '*',
          )
        }
      }
    }, 300))
  },
  showPopupButton: function showPopupButton() {
    var alias, buttonObject
    alias = function () {
      try {
        return this.popupData.params.payment.pm_alias
      } catch (error) {
        return ''
      }
    }.call(this)
    buttonObject = document.getElementById('__bootpay-close-button')
    buttonObject.classList.remove('naverpay-btn')
    // 네이버페이인 경우 네이버페이 색상으로 편집
    if (alias === 'npay') {
      document.getElementById('__bootpay_close_button_title').innerText =
        '네이버페이로 결제를 시작합니다'
      buttonObject.innerText = '네이버페이로 결제하기'
      buttonObject.classList.add('naverpay-btn')
    }
    return this.showProgressButton()
  },
}
