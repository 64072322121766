import Y2030 from './Y2030'
import Y2031 from './Y2031'
import Y2032 from './Y2032'
import Y2033 from './Y2033'
import Y2034 from './Y2034'
import Y2035 from './Y2035'
import Y2036 from './Y2036'
import Y2037 from './Y2037'
import Y2038 from './Y2038'
import Y2039 from './Y2039'

export const Y203 = {
  Y2030: Y2030,
  Y2031: Y2031,
  Y2032: Y2032,
  Y2033: Y2033,
  Y2034: Y2034,
  Y2035: Y2035,
  Y2036: Y2036,
  Y2037: Y2037,
  Y2038: Y2038,
  Y2039: Y2039,
}
