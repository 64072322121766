'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? function (obj) {
        return typeof obj
      }
    : function (obj) {
        return obj &&
          typeof Symbol === 'function' &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj
      } // Generated by CoffeeScript 2.5.1

var _logger = require('../logger')

var _logger2 = _interopRequireDefault(_logger)

var _superagent = require('superagent')

var _superagent2 = _interopRequireDefault(_superagent)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}

exports.default = {
  // 결제 정보를 보내 부트페이에서 결제 정보를 띄울 수 있게 한다.
  request: function request(data) {
    var lazy =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false

    var e, encryptData, html, ref, ref1, ref2, ref3, user
    if (this.isPaymentLock()) {
      return
    }
    this.removePaymentWindow(false)
    this.setPaymentLock(true)
    this.bindBootpayPaymentEvent()
    this.setConfirmLock(false)
    try {
      user = this.getUserData()
      if (data.application_id != null) {
        // 결제 효청시 application_id를 입력하면 덮어 씌운다. ( 결제 이후 버그를 줄이기 위한 노력 )
        this.applicationId = data.application_id
      }
      this.tk = this.generateUUID() + '-' + new Date().getTime()
      this.params = {
        application_id: this.applicationId,
        show_agree_window:
          data.show_agree_window != null ? data.show_agree_window : 0,
        device_type: this.deviceType,
        method: data.method != null ? data.method : void 0,
        methods: data.methods != null ? data.methods : void 0,
        user_token: data.user_token != null ? data.user_token : void 0,
        pg: data.pg != null ? data.pg : void 0,
        name: data.name,
        items: ((ref = data.items) != null ? ref.length : void 0)
          ? data.items
          : void 0,
        redirect_url: data.redirect_url != null ? data.redirect_url : '',
        return_url: data.return_url != null ? data.return_url : '',
        phone: ((ref1 = data.phone) != null ? ref1.length : void 0)
          ? data.phone.replace(/-/g, '')
          : '',
        uuid: ((ref2 = data.uuid) != null ? ref2.length : void 0)
          ? data.uuid
          : this.getData('uuid'),
        order_id: data.order_id != null ? String(data.order_id) : '',
        use_order_id: data.use_order_id != null ? data.use_order_id : 0,
        user_info: data.user_info != null ? data.user_info : void 0,
        order_info: data.order_info != null ? data.order_info : {}, // 네이버페이 order 정보
        sk: this.getData('sk'),
        time: this.getData('time'),
        price: data.price,
        tax_free: data.tax_free != null ? data.tax_free : 0,
        format: data.format != null ? data.format : 'json',
        params: data.params != null ? data.params : void 0,
        user_id: user != null ? user.id : void 0,
        path_url: document.URL,
        extra: data.extra != null ? data.extra : void 0,
        account_expire_at:
          data.account_expire_at != null ? data.account_expire_at : void 0,
        tk: this.tk,
      }
      if (!lazy) {
        // 각 함수 호출 callback을 초기화한다.
        // async의 경우엔 초기화하지 않는다
        this.methods = {}
      }
      this.extraValueAppend()
      if ((ref3 = this.params.items) != null ? ref3.length : void 0) {
        // 아이템 정보의 Validation
        this.integrityItemData()
      }
      // 결제 정보 데이터의 Validation
      this.integrityParams()
      // True, False의 데이터를 1, 0으로 변경하는 작업을 한다
      this.generateTrueFalseParams()
      // 데이터를 AES로 암호화한다.
      encryptData = this.encryptParams(this.params)
      html =
        '<div id="' +
        this.windowId +
        '">\n  <form name="bootpay_form" action="' +
        [this.restUrl(), 'start', 'js', '?ver=' + this.version].join('/') +
        '" method="POST">\n    <input type="hidden" name="data" value="' +
        encryptData.data +
        '" />\n    <input type="hidden" name="session_key" value="' +
        encryptData.session_key +
        '" />\n  </form>\n  <form id="__BOOTPAY_TOP_FORM__" name="__BOOTPAY_TOP_FORM__" action="' +
        [this.restUrl(), 'continue'].join('/') +
        '" method="post">\n  </form>\n  <form id="bootpay_confirm_form" name="bootpay_confirm_form" action="' +
        [this.restUrl(), 'confirm'].join('/') +
        '" method="POST">\n  </form>\n  <div class="bootpay-window" id="bootpay-background-window">' +
        this.iframeHtml('') +
        '</div>\n</div>'
      document.body.insertAdjacentHTML('beforeend', html)
      try {
        document.body.classList.add('bootpay-open')
      } catch (error) {
        ;('')
      }
      this.start()
    } catch (error) {
      e = error
      this.sendPaymentStepData({
        step: 'start',
        status: -1,
        e: e,
      })
      this.setPaymentLock(false)
      throw e
    }
    this.sendPaymentStepData({
      step: 'start',
      status: 1,
    })
    return this
  },
  startPaymentByUrl: function startPaymentByUrl(url) {
    var tk =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : void 0

    var e, html
    try {
      this.bindBootpayPaymentEvent()
      this.removePaymentWindow(false)
      this.setConfirmLock(false)
      this.tk = (tk != null ? tk.length : void 0)
        ? tk
        : this.generateUUID() + '-' + new Date().getTime()
      html =
        '<div id="' +
        this.windowId +
        '">\n  <form name="bootpay_form" action="' +
        url +
        '" method="GET">\n    <input type="hidden" name="tk" value="' +
        this.tk +
        '" />\n  </form>\n  <form id="__BOOTPAY_TOP_FORM__" name="__BOOTPAY_TOP_FORM__" action="' +
        [this.restUrl(), 'continue'].join('/') +
        '" method="post">\n  </form>\n  <form id="bootpay_confirm_form" name="bootpay_confirm_form" action="' +
        [this.restUrl(), 'confirm'].join('/') +
        '" method="POST">\n  </form>\n  <div class="bootpay-window" id="bootpay-background-window">' +
        this.iframeHtml('') +
        '</div>\n</div>'
      document.body.insertAdjacentHTML('beforeend', html)
      try {
        document.body.classList.add('bootpay-open')
      } catch (error) {
        ;('')
      }
      this.start()
    } catch (error) {
      e = error
      this.sendPaymentStepData({
        step: 'start',
        status: -1,
        e: e,
      })
      throw e
    }
    this.sendPaymentStepData({
      step: 'start',
      status: 1,
    })
    return this
  },
  //  결제 요청 정보 Validation
  integrityParams: function integrityParams() {
    var e, price, ref, ref1, ref2, ref3, ref4, ref5
    price = parseFloat(this.params.price)
    try {
      if (
        (isNaN(price) || price < 100) &&
        (this.zeroPaymentMethod.indexOf(this.params.method) === -1 ||
          !((ref = this.params.method) != null ? ref.length : void 0) ||
          !((ref1 = this.params.pg) != null ? ref1.length : void 0))
      ) {
        throw '결제할 금액을 설정해주세요. ( 100원 이상, 본인인증/정기 결제요청의 경우엔 0원을 입력해주세요. ) [ params: price ]'
      }
      if (!((ref2 = this.params.name) != null ? ref2.length : void 0)) {
        throw '판매할 상품명을 입력해주세요. [ params: name ]'
      }
      if (this.blockIEVersion()) {
        throw '익스플로러 8이하 버전에서는 결제가 불가능합니다.'
      }
      if (
        ((ref3 = this.params.phone) != null ? ref3.length : void 0) &&
        !this.phoneRegex.test(this.params.phone)
      ) {
        throw '휴대폰 번호의 자리수와 형식이 맞지 않습니다. [ params : phone ]'
      }
      if (!((ref4 = this.params.order_id) != null ? ref4.length : void 0)) {
        throw '판매하려는 제품 order_id를 지정해주셔야합니다. 다른 결제 정보와 겹치지 않은 유니크한 값으로 정해서 보내주시기 바랍니다. [ params: order_id ]'
      }
      if (
        ((ref5 = this.params.account_expire_at) != null
          ? ref5.length
          : void 0) &&
        !this.dateFormat.test(this.params.account_expire_at) &&
        this.params.method === 'vbank'
      ) {
        throw '가상계좌 입금 만료일 포멧이 잘못되었습니다. yyyy-mm-dd로 입력해주세요. [ params: account_expire_at ]'
      }
      if (this.params.methods != null && !Array.isArray(this.params.methods)) {
        throw '선택 제한 결제 수단 설정은 배열 형태로 보내주셔야 합니다. [ params: methods, ex) ["card", "phone"] ]'
      }
    } catch (error) {
      e = error
      alert(e)
      _logger2.default.error(e)
      throw e
    }
  },
  // 아이템 정보 Validation
  integrityItemData: function integrityItemData() {
    var e
    try {
      if (!Array.isArray(this.params.items)) {
        throw '아이템 정보가 배열 형태가 아닙니다.'
      }
      return this.params.items.forEach(function (item, index) {
        var ref, ref1
        if (!((ref = item.item_name) != null ? ref.length : void 0)) {
          throw (
            '\uD1B5\uACC4\uC5D0 \uD544\uC694\uD55C \uC544\uC774\uD15C \uC774\uB984\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. [key: item_name, index: ' +
            index +
            '] '
          )
        }
        if (item.qty == null) {
          throw (
            '\uD1B5\uACC4\uC5D0 \uD544\uC694\uD55C \uC0C1\uD488 \uD310\uB9E4 \uAC1C\uC218\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4. [key: qty, index: ' +
            index +
            ']'
          )
        }
        if (isNaN(parseInt(item.qty))) {
          throw (
            '\uC0C1\uD488 \uD310\uB9E4 \uAC1C\uC218\uB97C \uC22B\uC790\uB85C \uC785\uB825\uD574\uC8FC\uC138\uC694. [key: qty, index: ' +
            index +
            ']'
          )
        }
        if (!((ref1 = item.unique) != null ? ref1.length : void 0)) {
          throw (
            '\uD1B5\uACC4\uB97C \uC704\uD55C \uC0C1\uD488\uC758 \uACE0\uC720\uAC12\uC744 \uB123\uC5B4\uC8FC\uC138\uC694. [key: unique, index: ' +
            index +
            ']'
          )
        }
        if (item.price == null) {
          throw (
            '\uD1B5\uACC4\uB97C \uC704\uD574 \uC0C1\uD488\uC758 \uAC1C\uBCC4 \uAE08\uC561\uC744 \uB123\uC5B4\uC8FC\uC138\uC694. [key: price, index: ' +
            index +
            ']'
          )
        }
        if (isNaN(parseInt(item.price))) {
          throw (
            '\uC0C1\uD488\uAE08\uC561\uC740 \uC22B\uC790\uB85C\uB9CC \uAC00\uB2A5\uD569\uB2C8\uB2E4. [key: price, index: ' +
            index +
            ']'
          )
        }
      })
    } catch (error) {
      e = error
      alert(e)
      _logger2.default.error(e)
      throw e
    }
  },
  // True, False -> 1, 0 으로 Generate 한다
  generateTrueFalseParams: function generateTrueFalseParams() {
    var index, key, results
    for (index in this.params) {
      if (this.params[index] === true) {
        this.params[index] = 1
      }
      if (this.params[index] === false) {
        this.params[index] = 0
      }
    }
    if (this.params.extra != null) {
      for (index in this.params.extra) {
        if (this.params.extra[index] === true) {
          this.params.extra[index] = 1
        }
        if (this.params.extra[index] === false) {
          this.params.extra[index] = 0
        }
      }
    }
    if (this.params.third_party != null) {
      results = []
      for (index in this.params.third_party) {
        if (
          this.params.third_party[index] != null &&
          _typeof(this.params.third_party[index]) === 'object'
        ) {
          results.push(
            function () {
              var results1
              results1 = []
              for (key in this.params.third_party[index]) {
                if (this.params.third_party[index][key] === true) {
                  this.params.third_party[index][key] = 1
                }
                if (this.params.third_party[index][key] === false) {
                  results1.push((this.params.third_party[index][key] = 0))
                } else {
                  results1.push(void 0)
                }
              }
              return results1
            }.call(this),
          )
        } else {
          if (this.params.third_party[index] === true) {
            this.params.third_party[index] = 1
          }
          if (this.params.third_party[index] === false) {
            results.push((this.params.third_party[index] = 0))
          } else {
            results.push(void 0)
          }
        }
      }
      return results
    }
  },
  // Extra value를 조건에 맞게 추가
  extraValueAppend: function extraValueAppend() {
    var base
    if (this.isSetQuickPopup) {
      if ((base = this.params).extra == null) {
        base.extra = {}
      }
      this.params.extra.quick_popup = true
      this.params.extra.popup = true
      return (this.isSetQuickPopup = false)
    }
  },
  // 결제창을 조립해서 만들고 부트페이로 결제 정보를 보낸다.
  // 보낸 이후에 app.bootpay.co.kr로 데이터를 전송한다.
  start: function start() {
    this.progressMessageShow('')
    if (
      this.params.extra != null &&
      this.params.extra.popup &&
      this.params.extra.quick_popup
    ) {
      return this.doStartPopup({
        width: '300',
        height: '300',
      })
    } else {
      return this.doStartIframe()
    }
  },
  // 기존 iFrame으로 결제를 시작한다
  doStartIframe: function doStartIframe() {
    if (this.popupInstance != null) {
      // 팝업이 떠있으면 일단 닫는다
      this.popupInstance.close()
    }
    document
      .getElementById(this.iframeId)
      .addEventListener('load', this.progressMessageHide)
    document.bootpay_form.target = 'bootpay_inner_iframe'
    return document.bootpay_form.submit()
  },
  // 팝업으로 결제를 시작한다
  doStartPopup: function doStartPopup(platform) {
    var spec
    if (this.popupInstance == null) {
      spec =
        platform != null && platform.width != null && platform.width > 0
          ? 'width=' +
            platform.width +
            ',height=' +
            platform.height +
            ',top=' +
            0 +
            ',left=' +
            0 +
            ',scrollbars=yes,toolbar=no, location=no, directories=no, status=no, menubar=no'
          : ''
      this.popupInstance = window.open('about:blank', 'bootpayPopup', spec)
    }
    this.showPopupEventProgress()
    document.bootpay_form.target = 'bootpayPopup'
    return document.bootpay_form.submit()
  },
  // 팝업으로 시작하는 조건 부 async request 추가
  popupAsyncRequest: function popupAsyncRequest(conditions, method) {
    var _this = this

    if (method == null) {
      return alert('비동기로 실행될 함수가 있어야 합니다.')
    }
    if (conditions) {
      // 먼저 팝업을 띄운다
      this.startQuickPopup()
    }
    // 함수 초기화
    this.methods = {}
    method.call().then(
      function (data) {
        return _this.request(data, true)
      },
      function (e) {
        _this.clearEnvironment(true)
        return _this.forceClose(e.message)
      },
    )
    return this
  },
  // 사용자 promise 가 발생되기 전 선 팝업을 띄운다
  startQuickPopup: function startQuickPopup() {
    this.isSetQuickPopup = true
    return this.expressPopupReady()
  },
  // 미리 팝업을 준비한다
  expressPopupReady: function expressPopupReady() {
    var spec
    if (
      typeof platform !== 'undefined' &&
      platform !== null &&
      platform.width != null &&
      platform.width > 0
    ) {
      spec =
        'width=' +
        platform.width +
        ',height=' +
        platform.height +
        ',top=' +
        0 +
        ',left=' +
        0 +
        ',scrollbars=yes,toolbar=no, location=no, directories=no, status=no, menubar=no'
    } else {
      spec = this.isMobile()
        ? ''
        : 'width=750,height=500,top=' +
          0 +
          ',left=' +
          0 +
          ',scrollbars=yes,toolbar=no, location=no, directories=no, status=no, menubar=no'
    }
    return (this.popupInstance = window.open(
      'https://inapp.bootpay.co.kr/waiting',
      'bootpayPopup',
      spec,
    ))
  },
  // 결제할 iFrame 창을 만든다.
  iframeHtml: function iframeHtml(url) {
    return (
      '<iframe id="' +
      this.iframeId +
      '" style="height: 0;" name="bootpay_inner_iframe" src="' +
      url +
      '" allowtransparency="true" scrolling="no"></iframe>\n<div class="progress-message-window" id="bootpay-progress-message">\n  <div class="progress-message">\n    <div class="bootpay-loading">\n      <div class="bootpay-loading-spinner">\n        <svg viewBox="25 25 50 50" class="bootpay-circle" xmlns="http://www.w3.org/2000/svg">\n          <circle cx="50" cy="50" r="20" fill="none" class="bootpay-path"></circle>\n        </svg>\n      </div>\n    </div>\n    <div class="bootpay-text">\n      <span class="bootpay-inner-text" id="progress-message-text"></span>\n    </div>\n    <div class="bootpay-popup-close" id="__bootpay-popup-close-button__" style="display: none;">\n      <button onclick="window.BootPay.closePopupWithPaymentWindow()">\xD7</button>\n    </div>\n  </div>\n</div>\n<div class="progress-message-window over" id="bootpay-progress-button-window">\n  <div class="close-message-box">\n    <div class="close-popup">\n      <div class="close-popup-header">\n        <button class="close-btn" onclick="window.BootPay.removePaymentWindow()">\xD7</button>\n      </div>\n      <h4 class="sub-title" id="__bootpay_close_button_title">\uC120\uD0DD\uD558\uC2E0 \uACB0\uC81C\uB294 \uD31D\uC5C5\uC73C\uB85C \uACB0\uC81C\uAC00 \uC2DC\uC791\uB429\uB2C8\uB2E4. \uACB0\uC81C\uB97C \uC2DC\uC791\uD560\uAE4C\uC694?</h4>\n      <button class="close-payment-window" onclick="window.BootPay.startPopup();" type="button" id="__bootpay-close-button">\uACB0\uC81C\uD558\uAE30</button>\n    </div>\n  </div>\n</div>'
    )
  },
  // 팝업결제를 실행한다
  startPopup: function startPopup() {
    return this.startPopupPaymentWindow(this.popupData)
  },
  // 간편결제 비밀번호 direct로 뜨게끔
  verifyPassword: function verifyPassword() {
    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    this.initializeEasySubmit(
      [this.clientUrl(), 'easy', 'password', 'verify'].join('/'),
      {
        user_token: data.userToken,
        device_id: data.deviceId,
        message: data.message,
      },
    )
    return this
  },
  // 비밀번호 등록
  registerCard: function registerCard() {
    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    this.initializeEasySubmit(
      [this.clientUrl(), 'easy', 'card', 'register'].join('/'),
      {
        user_token: data.userToken,
        device_id: data.deviceId,
        message: data.message,
      },
    )
    return this
  },
  changePassword: function changePassword() {
    var data =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    this.initializeEasySubmit(
      [this.clientUrl(), 'easy', 'password', 'change'].join('/'),
      {
        user_token: data.userToken,
        device_id: data.deviceId,
        message: data.message,
      },
    )
    return this
  },
  // 간편결제 관련 Form Submit
  initializeEasySubmit: function initializeEasySubmit(url) {
    var data =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}

    var encryptData
    encryptData = this.encryptParams(data)
    document.body.insertAdjacentHTML(
      'beforeend',
      '<div id="' +
        this.windowId +
        '">\n  <form name="bootpayEasyForm" action="' +
        url +
        '" method="GET">\n    <input type="hidden" name="data" value="' +
        encryptData.data +
        '" />\n    <input type="hidden" name="session_key" value="' +
        encryptData.session_key +
        '" />\n  </form>\n  <div class="bootpay-window" id="bootpay-background-window">' +
        this.iframeHtml('') +
        '</div>\n</div>',
    )
    this.bindEasyEvent()
    try {
      document.body.classList.add('bootpay-open')
    } catch (error) {
      ;('')
    }
    document.getElementById(this.iframeId).style.setProperty('height', '100%')
    document.bootpayEasyForm.target = 'bootpay_inner_iframe'
    return document.bootpayEasyForm.submit()
  },
  // 결제를 승인한다
  transactionConfirm: function transactionConfirm(data) {
    var html
    if (this.isConfirmLock()) {
      console.log('Transaction Lock')
    } else {
      this.setConfirmLock(true)
      if (data == null || data.receipt_id == null) {
        alert(
          '결제 승인을 하기 위해서는 receipt_id 값이 포함된 data값을 함께 보내야 합니다.',
        )
        _logger2.default.error(
          'this.transactionConfirm(data); 이렇게 confirm을 실행해주세요.',
        )
        return
      }
      html =
        '<input type="hidden" name="receipt_id" value="' +
        data.receipt_id +
        '" />\n<input type="hidden" name="application_id" value="' +
        this.applicationId +
        '" />'
      document.getElementById('bootpay_confirm_form').innerHTML = html
      document.bootpay_confirm_form.action =
        [this.restUrl(), 'confirm'].join('/') + '?' + this.generateUUID()
      document.bootpay_confirm_form.target = 'bootpay_inner_iframe'
      document.bootpay_confirm_form.submit()
    }
    return this
  },
}
