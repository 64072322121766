import Y2040 from './Y2040'
import Y2041 from './Y2041'
import Y2042 from './Y2042'
import Y2043 from './Y2043'
import Y2044 from './Y2044'
import Y2045 from './Y2045'
import Y2046 from './Y2046'
import Y2047 from './Y2047'
import Y2048 from './Y2048'
import Y2049 from './Y2049'

export const Y204 = {
  Y2040: Y2040,
  Y2041: Y2041,
  Y2042: Y2042,
  Y2043: Y2043,
  Y2044: Y2044,
  Y2045: Y2045,
  Y2046: Y2046,
  Y2047: Y2047,
  Y2048: Y2048,
  Y2049: Y2049,
}
