import {LunarYear} from './LunarYear'
import {Y190} from './data/1900'
import {Y191} from './data/1910'
import {Y192} from './data/1920'
import {Y193} from './data/1930'
import {Y194} from './data/1940'
import {Y195} from './data/1950'
import {Y196} from './data/1960'
import {Y197} from './data/1970'
import {Y198} from './data/1980'
import {Y199} from './data/1990'
import {Y200} from './data/2000'
import {Y201} from './data/2010'
import {Y202} from './data/2020'
import {Y203} from './data/2030'
import {Y204} from './data/2040'
import {Y205} from './data/2050'
import {Y206} from './data/2060'
import {Y207} from './data/2070'
import {Y208} from './data/2080'
import {Y209} from './data/2090'
import {Y210} from './data/2100'

interface Lunar {
  [name: string]: LunarYear
}

const lunar: Lunar = {
  ...Y190,
  ...Y191,
  ...Y192,
  ...Y193,
  ...Y194,
  ...Y195,
  ...Y196,
  ...Y197,
  ...Y198,
  ...Y199,
  ...Y200,
  ...Y201,
  ...Y202,
  ...Y203,
  ...Y204,
  ...Y205,
  ...Y206,
  ...Y207,
  ...Y208,
  ...Y209,
  ...Y210,
}

export default lunar
