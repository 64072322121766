import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'
import {TodayActionTypes} from './types'
import {OwnerState} from '../owner/types'
import {AppState} from '../AppState'
import axios from 'axios'
import {getOwnerInfoFromOwnerState} from '../utils'
import {changeTodayAnalysis, changeTodayLoadingState} from './actions'
import {API_ENDPOINT} from '../../properies'
import {scroller} from 'react-scroll/modules'
import {EumYang} from '../../enums/EumYang'

function* handleFetchTodayAnalysis() {
  const owner: OwnerState = yield select((appState: AppState) => appState.owner)
  // const isOwnerInfoValid = checkYear(owner.year) && checkMonth(owner.month) && checkDay(owner.year, owner.month, owner.day, owner.calendar, owner.isLeapMonth) && checkHour(owner.hour) && checkMinute(owner.minute);

  const isLoading: boolean = yield select(
    (appState: AppState) => appState.today.isLoading,
  )
  if (isLoading) {
    return
  }
  const localStorage = window.localStorage
  localStorage.setItem('owner', JSON.stringify(owner))

  try {
    const ownerInfo = getOwnerInfoFromOwnerState(owner)

    if (process.env.NODE_ENV === 'production') {
      gtag('event', 'click_today_fortune', {
        age: ownerInfo.year,
        gender: owner.sex === EumYang.YANG ? 'male' : 'female',
      })
    }
    yield put(changeTodayLoadingState(true))
    const res = yield call(axios.post, `${API_ENDPOINT}/today`, {
      ...ownerInfo,
    })
    scroller.scrollTo('after-load', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeInOutQuart',
    })
    yield put(changeTodayAnalysis(res.data))
  } catch (e) {
    // const errorRes = e.response.data;
    // yield put(changeError(true));
    // yield put(changeErrorType(errorRes.code));
    // yield put(changeErrorMessage(errorRes.message));
    // if (errorRes.code === ErrorType.INVALID_TOKEN) {
    //   yield put(logout());
    // }
    // return;
  } finally {
    yield put(changeTodayLoadingState(false))
  }
}

function* watchFetchTodayAnalysis() {
  yield takeLatest(
    TodayActionTypes.FETCH_TODAY_ANALYSIS,
    handleFetchTodayAnalysis,
  )
}

function* todaySaga() {
  yield all([fork(watchFetchTodayAnalysis)])
}

export default todaySaga
