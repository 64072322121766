import {TodayAnalysis} from '../../types/saJoo/TodayAnalysis'
import {ActionType} from 'typesafe-actions'
import * as TodayActions from './actions'

export enum TodayActionTypes {
  FETCH_TODAY_ANALYSIS = '@@today/FETCH_TODAY_ANALYSIS',
  CHANGE_TODAY_ANALYSIS = '@@today/CHANGE_TODAY_ANALYSIS',
  CHANGE_LOADING_STATE = '@@today/CHANGE_LOADING_STATE',
}

export interface TodayState {
  isLoading: boolean
  todayAnalysis?: TodayAnalysis
}

export type TodayAction = ActionType<typeof TodayActions>
