import {CommonAction, CommonActionTypes, CommonState, ErrorType} from './types'
import {Reducer} from 'redux'

const initialState: CommonState = {
  scrollY: 0,
  mobileMenuOpen: false,
  recentListOpen: false,
  error: false,
  errorType: ErrorType.NONE,
  errorMessage: '',
  notification: false,
  notificationMessage: '',
  moveToAfterLoad: false,
}
const reducer: Reducer<CommonState, CommonAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CommonActionTypes.CHANGE_SCROLL_Y: {
      return {...state, scrollY: action.payload.position}
    }
    case CommonActionTypes.CHANGE_MOBILE_MENU_OPEN: {
      return {...state, mobileMenuOpen: action.payload.open}
    }
    case CommonActionTypes.CHANGE_ERROR:
      return {...state, error: action.payload.error}
    case CommonActionTypes.CHANGE_ERROR_TYPE:
      return {...state, errorType: action.payload.errorType}
    case CommonActionTypes.CHANGE_ERROR_MESSAGE:
      return {...state, errorMessage: action.payload.errorMessage}
    case CommonActionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: false,
        errorType: ErrorType.NONE,
        errorMessage: '',
      }
    case CommonActionTypes.CHANGE_NOTIFICATION:
      return {...state, notification: action.payload.notification}
    case CommonActionTypes.CHANGE_NOTIFICATION_MESSAGE:
      return {...state, notificationMessage: action.payload.notificationMessage}
    case CommonActionTypes.CLEAR_NOTIFICATION:
      return {...state, notification: false, notificationMessage: ''}
    case CommonActionTypes.CHANGE_MOVE_TO_AFTER_LOAD:
      return {...state, moveToAfterLoad: action.payload.val}
    default:
      return state
  }
}

export {reducer as commonReducer}
