import Y2020 from './Y2020'
import Y2021 from './Y2021'
import Y2022 from './Y2022'
import Y2023 from './Y2023'
import Y2024 from './Y2024'
import Y2025 from './Y2025'
import Y2026 from './Y2026'
import Y2027 from './Y2027'
import Y2028 from './Y2028'
import Y2029 from './Y2029'

export const Y202 = {
  Y2020: Y2020,
  Y2021: Y2021,
  Y2022: Y2022,
  Y2023: Y2023,
  Y2024: Y2024,
  Y2025: Y2025,
  Y2026: Y2026,
  Y2027: Y2027,
  Y2028: Y2028,
  Y2029: Y2029,
}
