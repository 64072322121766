import {DaySuccintInfos} from '../../types/pickDay/DaySuccintInfos'
import {ActionType} from 'typesafe-actions'
import * as PickDayActions from './actions'
import {GoodDay} from '../../types/pickDay/GoodDay'

export enum PickDayActionTypes {
  FETCH_PICK_BIRTH_DAY_INFOS = '@@pickDay/FETCH_PICK_DAY_INFOS',
  CHANGE_PICK_BIRTH_DAY_INFOS = '@@pickDay/CHANGE_PICK_DAY_INFOS',
  CHANGE_PICK_DAY_IS_LOADING = '@@pickDay/CHANGE_PICK_DAY_IS_LOADING',
  FETCH_GOOD_DAY_INFOS = '@@pickDay/FETCH_GOOD_DAY_INFOS',
  CHANGE_GOOD_DAY_INFOS = '@@pickDay/CHANGE_GOOD_DAY_INFOS',
}

export interface PickDayState {
  pickBirthDayInfos: DaySuccintInfos[]
  goodDayInfos: GoodDay[]
  isLoading: boolean
}

export type PickDayAction = ActionType<typeof PickDayActions>
