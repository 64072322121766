import React from 'react'
import {Link} from 'gatsby'

export const Footer: React.FC = () => {
  return (
    <footer className="container max-w-screen-xl mx-auto py-16 px-3 mt-24 mb-8 text-gray-800">
      <div className="px-6 md:px-12 md:flex  -mx-3">
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">중요 링크</h2>
          <ul className="mt-4 leading-loose">
            <li>
              <Link to="/terms">이용 약관</Link>
            </li>
            <li>
              <Link to="/privacy">개인정보처리방침</Link>
            </li>
            <li>
              <Link to="/customer-service">고객 센터</Link>
            </li>
          </ul>
        </div>
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">서비스</h2>
          <ul className="mt-4 leading-loose">
            <li>
              <Link to="/sajoo">사주 풀이</Link>
            </li>
            <li>
              <Link to="/today">오늘의 운세</Link>
            </li>
            <li>
              <Link to="/pick-day">일반 택일</Link>
            </li>
            <li>
              <Link to="/pick-birthday">출산 택일</Link>
            </li>
          </ul>
        </div>
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">(유)어른왕자</h2>
          <p className="mt-5 text-sm font-normal">
            카카오톡 채널 8-codes
            <br/>
            이메일 support@8-codes.com
            <br />
            경기도 이천시 덕평로 788
            <br />
            전화번호 070-7576-1062
            <br />
            사업자등록번호 838-81-01607
            <br />
            통신판매업신고번호 제2020-경기이천-0059호
            <br />
            대표 원찬희
          </p>
        </div>
      </div>
    </footer>
  )
}
