import {TodayAction, TodayActionTypes, TodayState} from './types'

const initialState: TodayState = {
  isLoading: false,
  todayAnalysis: undefined,
}

export function todayReducer(
  state = initialState,
  action: TodayAction,
): TodayState {
  switch (action.type) {
    case TodayActionTypes.CHANGE_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
    case TodayActionTypes.CHANGE_TODAY_ANALYSIS:
      return {
        ...state,
        todayAnalysis: action.payload.todayAnalysis,
      }

    default:
      return state
  }
}
