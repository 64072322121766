import {Reducer} from 'redux'
import {
  Bank,
  PaymentAction,
  PaymentActionTypes,
  PaymentState,
  ProductType,
} from './types'

const initialState: PaymentState = {
  price: 0,
  product: ProductType.NONE,

  payBoxOpen: false,
}

export const paymentReducer: Reducer<PaymentState, PaymentAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PaymentActionTypes.CHANGE_PRICE: {
      return {...state, price: action.payload.price}
    }

    case PaymentActionTypes.CHANGE_PRODUCT: {
      return {...state, product: action.payload.product}
    }
    //
    // case PaymentActionTypes.CHANGE_PROCESS_STATE_IN_OUR_SERVER: {
    //     return {...state, processState: action.payload.state};
    // }
    //
    //
    // case PaymentActionTypes.CHANGE_PAID_PRODUCT: {
    //     return {...state, paidProduct: action.payload.productName};
    // }
    //
    // case PaymentActionTypes.CHANGE_PAID_PRICE: {
    //     return {...state, paidPrice: action.payload.price};
    // }
    //
    // case PaymentActionTypes.CHANGE_AGREE_TERMS: {
    //     return {
    //         ...state, agreeTerms: action.payload.agree
    //     };
    // }
    //
    // case PaymentActionTypes.DO_VBANK_PROCESS: {
    //     return {
    //         ...state,
    //         bankname: action.payload.bankname,
    //         username: action.payload.username,
    //         price: action.payload.price,
    //         account: action.payload.account,
    //         accountholder: action.payload.accountholder,
    //         expiredate: action.payload.expiredate,
    //         processState: ProcessState.WAITING_FOR_VBANK,
    //     };
    // }
    //
    // case PaymentActionTypes.CLEAR_ALL_PAYMENT_RESULT: {
    //     return {...initialState, selectedPrice: state.selectedPrice};
    // }
    //
    // case PaymentActionTypes.CHANGE_IS_ACCOUNT_TRANSFER_OPEN: {
    //     return {...state, isAccountTransferOpen: action.payload.open};
    // }
    //
    // case PaymentActionTypes.CHANGE_PAYER: {
    //     return {...state, payer: action.payload.payer};
    // }
    //
    // case PaymentActionTypes.CHANGE_SELECTED_BANK: {
    //     return {...state, selectedBank: action.payload.bank};
    // }
    //
    // case PaymentActionTypes.CHANGE_PHONE_NUMBER: {
    //     return {...state, phoneNumber: action.payload.phoneNumber};
    // }
    //
    // case PaymentActionTypes.CHANGE_DEPOSIT_WAITING_STATUS: {
    //     return {...state, depositWaitingStatus: action.payload.depositWaitingStatus};
    // }
    //
    // case PaymentActionTypes.CHANGE_COMPLETED_DEPOSIT_NOTIFICATION: {
    //     return {...state, completedDepositRecord: action.payload.completedDepositRecord};
    // }

    case PaymentActionTypes.CHANGE_PAYBOX_OPEN_STATE: {
      return {...state, payBoxOpen: action.payload.state}
    }
    default:
      return state
  }
}
