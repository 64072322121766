// @ts-ignore
import {Provider} from 'react-redux'
import React, {ReactElement} from 'react'
import makeStore from './src/store'
import MobileMenuContainer from './src/components/mobile-menu/mobil-menu-container'
import {Footer} from './src/components/footer/Footer'
import ErrorContainer from './src/components/error/error-container'
import './src/styles/global.css'
import PayBox from './src/components/pay-box/pay-box'
import HeadMenuBar from "./src/components/header/HeaderMenuBar";
import LoadingBox from "./src/components/loading-box/loading-box";

export default ({element}: { element: ReactElement }) => (
  <Provider store={makeStore()}>
    <LoadingBox/>
    <HeadMenuBar/>
    <MobileMenuContainer/>
    {element}
    <Footer/>
    <ErrorContainer/>
    <PayBox/>
  </Provider>
)
