import {OwnerAction, OwnerActionTypes, OwnerState} from './types'
import * as localStorage from 'local-storage'
import {EumYang} from '../../enums/EumYang'

// const localStorage = window.localStorage;

const saveOwner = localStorage.get<OwnerState>('owner')

const initialState: OwnerState = saveOwner
  ? {...saveOwner}
  : {
      sex: EumYang.YANG,
      calendar: EumYang.YANG,
      year: '1970',
      month: '1',
      day: '1',
      hour: '1',
      minute: '0',
      isLeapMonth: false,
    }

export function ownerReducer(
  state = initialState,
  action: OwnerAction,
): OwnerState {
  switch (action.type) {
    case OwnerActionTypes.CHANGE_SEX:
      return {
        ...state,
        sex: action.payload.sex,
      }

    case OwnerActionTypes.CHANGE_CALENDAR:
      return {
        ...state,
        calendar: action.payload.calendar,
      }
    case OwnerActionTypes.CHANGE_YEAR:
      return {
        ...state,
        year: action.payload.year,
      }
    case OwnerActionTypes.CHANGE_MONTH:
      return {
        ...state,
        month: action.payload.month,
      }
    case OwnerActionTypes.CHANGE_DAY:
      return {
        ...state,
        day: action.payload.day,
      }
    case OwnerActionTypes.CHANGE_HOUR:
      return {
        ...state,
        hour: action.payload.hour,
      }
    case OwnerActionTypes.CHANGE_MINUTE:
      return {
        ...state,
        minute: action.payload.minute,
      }
    case OwnerActionTypes.CHANGE_IS_LEAPMONTH:
      return {
        ...state,
        isLeapMonth: action.payload.isLeapMonth,
      }
    case OwnerActionTypes.CHANGE_FULL_STATE:
      return {
        ...action.payload.owner,
      }
    default:
      return state
  }
}
