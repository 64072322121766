import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects'
import axios from 'axios'
import {API_ENDPOINT} from '../../properies'
import {PickDayActionTypes} from './types'
import {
  changeGoodDayInfos,
  changePickBirthDayInfo,
  changePickDayIsLoading,
} from './actions'
import {scroller} from 'react-scroll/modules'
import moment from 'moment-timezone'
import {OwnerState} from '../owner/types'
import {AppState} from '../AppState'
import {EumYang} from '../../enums/EumYang'
import {getOwnerInfoFromOwnerState} from '../utils'

function* handleFetchPickBirthDayInfosAction() {
  try {
    if (process.env.NODE_ENV === 'production')
      gtag('event', 'click_pick_birthday')

    yield put(changePickDayIsLoading(true))
    const res = yield call(
      axios.get,
      `${API_ENDPOINT}/sajoo/thirty_days?day=${moment
        .tz('Asia/Seoul')
        .format('YYYYMMDD')}`,
      {},
    )
    yield put(changePickBirthDayInfo(res.data))
    scroller.scrollTo('after-load', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeInOutQuart',
    })
  } catch (e) {
  } finally {
    yield put(changePickDayIsLoading(false))
  }
}

function* handleFetchGoodDaysInfoAction() {
  try {
    yield put(changePickDayIsLoading(true))
    const owner: OwnerState = yield select(
      (appState: AppState) => appState.owner,
    )
    const ownerInfo = getOwnerInfoFromOwnerState(owner)

    if (process.env.NODE_ENV === 'production') {
      gtag('event', 'click_pick_day', {
        age: ownerInfo.year,
        gender: ownerInfo.sex === EumYang.YANG ? 'male' : 'female',
      })
    }

    const localStorage = window.localStorage
    localStorage.setItem('owner', JSON.stringify(owner))
    const {year, month, day, sex} = ownerInfo
    const res = yield call(
      axios.get,
      `${API_ENDPOINT}/pickday/good_days?year=${year}&month=${month}&day=${day}&gender=${sex}&today=${moment
        .tz('Asia/Seoul')
        .format('YYYYMMDD')}`,
      {},
    )
    yield put(changeGoodDayInfos(res.data))
    scroller.scrollTo('after-load', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeInOutQuart',
    })
  } catch (e) {
  } finally {
    yield put(changePickDayIsLoading(false))
  }
}

function* watchFetchPickBirthDayInfosAction() {
  yield takeLatest(
    PickDayActionTypes.FETCH_PICK_BIRTH_DAY_INFOS,
    handleFetchPickBirthDayInfosAction,
  )
}

function* watchFetchGoodDayInfosAction() {
  yield takeLatest(
    PickDayActionTypes.FETCH_GOOD_DAY_INFOS,
    handleFetchGoodDaysInfoAction,
  )
}

function* pickDaySaga() {
  yield all([
    fork(watchFetchPickBirthDayInfosAction),
    fork(watchFetchGoodDayInfosAction),
  ])
}

export default pickDaySaga
