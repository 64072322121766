import Y1930 from './Y1930'
import Y1931 from './Y1931'
import Y1932 from './Y1932'
import Y1933 from './Y1933'
import Y1934 from './Y1934'
import Y1935 from './Y1935'
import Y1936 from './Y1936'
import Y1937 from './Y1937'
import Y1938 from './Y1938'
import Y1939 from './Y1939'

export const Y193 = {
  Y1930: Y1930,
  Y1931: Y1931,
  Y1932: Y1932,
  Y1933: Y1933,
  Y1934: Y1934,
  Y1935: Y1935,
  Y1936: Y1936,
  Y1937: Y1937,
  Y1938: Y1938,
  Y1939: Y1939,
}
