import {LunarYear} from '../../LunarYear'
import moment from 'moment-timezone'

const Y2002 = new LunarYear(
  moment.tz('2002-02-12 00:00', 'Asia/Seoul'),
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [30, 30, 29, 30, 29, 30, 29, 29, 30, 29, 30, 29],
)

export default Y2002
