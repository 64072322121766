import * as PaymentActions from './/actions'
import {ActionType} from 'typesafe-actions'

export interface PaymentState {
  price: number
  product: ProductType

  // pay box open/close
  payBoxOpen: boolean
}

export enum PaymentActionTypes {
  CHANGE_PRICE = '@@payment/CHANGE_PRICE',
  CHANGE_PRODUCT = '@@payment/CHANGE_PRODUCT',
  REQUEST_PAYMENT = '@@payment/REQUEST_PAYMENT',
  THROW_PAYMENT_ERROR = '@@payment/THROW_PAYMENT_ERROR',
  CANCEL_PAYMENT = '@@payment/CANCEL_PAYMENT',
  DO_PROCESS_IN_OUR_SERVER = '@@payment/DO_PROCESS_IN_OUT_SERVER',

  // pay box open/close 상태 변경
  CHANGE_PAYBOX_OPEN_STATE = '@@payment/CHANGE_PAYBOX_OPEN_STATE',
}

export enum ProcessState {
  NOT_STARTED = 'NOT_STARTED', // 시작 전.
  IN_PROCESSING = 'IN_PROCESSING',
  COMPLETED = 'COMPLETED', // 나이스 페이 결제 완료.
  WAITING_FOR_VBANK = 'WAITING_FOR_VBANK', // 나이스페이 가상 계좌 이체 대기중.
  WAITING_FOR_DEPOSIT = 'WATING_FOR_DEPOSIT', // 법인 계좌로 이체 대기중.
}

// 입금 대기 상태 정보
export interface DepositWaitingStatus {
  bank: string // 입금할 은행.
  accountNumber: string // 계좌 번호.
  accountOwner: string // 예금주.
  depositor: string // 입금자.
  price: number // 입금액
  due: number // 입금 기한
  phoneNumber: number // 입금자 전화번호
}

// 완료된 계좌 이체 정보
export interface CompletedDepositRecord {
  bank: string
  processed_at: string
  amount: number
  email: string
  confirmed: boolean
}

export type PaymentAction = ActionType<typeof PaymentActions>

export enum Bank {
  SHINHAN = '신한',
  KOOKMIN = '국민',
  WOORI = '우리',
  HANA = '하나',
  NH = 'NH농협',
  IBK = 'IBK기업',
}

export enum ProductType {
  NONE = '',
  SAJOO = '8CODES_SJ',
  GOONGHAB = '8CODES_GH',
}

export enum PaymentMethod {
  CARD = 'card',
  KAKAO = 'kakao',
}
