import React from 'react'
import {CircularProgress} from "@material-ui/core";
import {AppState} from "../../store/AppState";
import {connect} from "react-redux";


interface Props {
  isLoading: boolean
}

const LoadingBox: React.FC<Props> = ({isLoading}) => {

  return (
    isLoading ? (
      <div
        className="fixed top-0 bottom-0 right-0 left-0 flex flex-col justify-center items-center bg-black bg-opacity-90 z-50 text-gray-100">
        <h3 className="text-gray-100 mb-1">
          사주 분석 중입니다.
        </h3>

        <p className="text-gray-100 mb-4">
          절대 뒤로 가기나 페이지 새로고침을 하지 마세요.
        </p>
        <CircularProgress color="inherit" size={50}/>
      </div>
    ): <div/>
  )
}

const mapStateToProps = ({sajoo}: AppState) => ({
  isLoading: sajoo.isLoading,
})


export default connect(mapStateToProps)(LoadingBox)