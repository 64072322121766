'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _aes = require('crypto-js/aes')

var _aes2 = _interopRequireDefault(_aes)

var _encBase = require('crypto-js/enc-base64')

var _encBase2 = _interopRequireDefault(_encBase)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {default: obj}
}

// Generated by CoffeeScript 2.5.1
exports.default = {
  encryptParams: function encryptParams(data) {
    var encryptData
    encryptData = _aes2.default.encrypt(
      JSON.stringify(data),
      this.getData('sk'),
    )
    return {
      data: encryptData.ciphertext.toString(_encBase2.default),
      session_key:
        encryptData.key.toString(_encBase2.default) +
        '##' +
        encryptData.iv.toString(_encBase2.default),
    }
  },
}
