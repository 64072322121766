import {PaymentActionTypes, PaymentMethod, ProductType} from './types'
import {action} from 'typesafe-actions'

export const changePrice = (price: number) =>
  action(PaymentActionTypes.CHANGE_PRICE, {price})

export const changeProduct = (product: ProductType) =>
  action(PaymentActionTypes.CHANGE_PRODUCT, {product})

export const requestPayment = (method: PaymentMethod) =>
  action(PaymentActionTypes.REQUEST_PAYMENT, {method})

export const throwPaymentError = (data: any) =>
  action(PaymentActionTypes.THROW_PAYMENT_ERROR, {data})

export const doProcessInOurServer = (data: any, product: ProductType) =>
  action(PaymentActionTypes.DO_PROCESS_IN_OUR_SERVER, {
    data,
    product,
  })

export const changePayBoxOpenState = (state: boolean) =>
  action(PaymentActionTypes.CHANGE_PAYBOX_OPEN_STATE, {state})
