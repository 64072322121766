// extracted by mini-css-extract-plugin
export const headerBox = "HeadMenuBar-module--headerBox--3oXyL";
export const onTop = "HeadMenuBar-module--onTop--3Tcx8";
export const noTop = "HeadMenuBar-module--noTop--21lbA";
export const menuBox = "HeadMenuBar-module--menuBox--2WceU";
export const menuIconBox = "HeadMenuBar-module--menuIconBox--3Re00";
export const menu = "HeadMenuBar-module--menu--3Flv7";
export const menuLink = "HeadMenuBar-module--menuLink--3AKh9";
export const menuLinkInPopup = "HeadMenuBar-module--menuLinkInPopup--1_aCT";
export const logoImg = "HeadMenuBar-module--logoImg--2i20I";
export const logoBox = "HeadMenuBar-module--logoBox--2Wywy";
export const popupMenuList = "HeadMenuBar-module--popupMenuList--kw-fT";
export const popupMenuLink = "HeadMenuBar-module--popupMenuLink--1EMWP";
export const styledLink = "HeadMenuBar-module--styledLink--1e5oL";
export const pointBox = "HeadMenuBar-module--pointBox--3MCHD";
export const tooltip = "HeadMenuBar-module--tooltip--E86l9";
export const tooltipBox = "HeadMenuBar-module--tooltipBox--2Huud";