import Y2060 from './Y2060'
import Y2061 from './Y2061'
import Y2062 from './Y2062'
import Y2063 from './Y2063'
import Y2064 from './Y2064'
import Y2065 from './Y2065'
import Y2066 from './Y2066'
import Y2067 from './Y2067'
import Y2068 from './Y2068'
import Y2069 from './Y2069'

export const Y206 = {
  Y2060: Y2060,
  Y2061: Y2061,
  Y2062: Y2062,
  Y2063: Y2063,
  Y2064: Y2064,
  Y2065: Y2065,
  Y2066: Y2066,
  Y2067: Y2067,
  Y2068: Y2068,
  Y2069: Y2069,
}
