import {LunarYear} from '../../LunarYear'
import moment from 'moment-timezone'

const Y1978 = new LunarYear(
  moment.tz('1978-02-07 00:00', 'Asia/Seoul'),
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [30, 30, 29, 30, 29, 30, 30, 29, 30, 29, 30, 29],
)

export default Y1978
