import React from 'react'
import {ErrorType} from '../../store/common/types'
import {AppState} from '../../store/AppState'
import {Dispatch} from 'redux'
import {clearError} from '../../store/common/actions'
import {connect} from 'react-redux'
import {
  popupOuter,
  popupBox,
  popupMessage,
  btnOuter,
  btn,
} from './error.module.scss'
import {navigate} from 'gatsby'

interface Props {
  error: boolean
  errorType: ErrorType
  errorMessage: string
  confirm: () => void
  routeToLogin: () => void
  routeToPrice: () => void
}

const ErrorContainer: React.FC<Props> = ({
  error,
  errorType,
  errorMessage,
  confirm,
  routeToLogin,
  routeToPrice,
}) => {
  if (error)
    return (
      <div className={popupOuter}>
        <div className={popupBox}>
          <div className={popupMessage}>{errorMessage}</div>
          <div className={btnOuter}>
            {errorType === ErrorType.INVALID_TOKEN ? (
              <button className={btn} onClick={() => routeToLogin()}>
                로그인
              </button>
            ) : undefined}

            {errorType === ErrorType.NOT_ENOUGH_POINT ? (
              <button className={btn} onClick={() => routeToPrice()}>
                충전
              </button>
            ) : undefined}

            <button className={btn} onClick={() => confirm()}>
              확인
            </button>
          </div>
        </div>
      </div>
    )
  else return <div />
}

const mapStateToProps = ({common}: AppState) => ({
  error: common.error,
  errorType: common.errorType,
  errorMessage: common.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirm: () => dispatch(clearError()),
  routeToLogin: () => {
    dispatch(clearError())
    navigate('/login')
  },
  routeToPrice: () => {
    dispatch(clearError())
    navigate('/price')
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer)
