import {action} from 'typesafe-actions'
import {TodayActionTypes} from './types'
import {TodayAnalysis} from '../../types/saJoo/TodayAnalysis'

export const fetchTodayAnalysis = () =>
  action(TodayActionTypes.FETCH_TODAY_ANALYSIS)
export const changeTodayAnalysis = (todayAnalysis: TodayAnalysis) =>
  action(TodayActionTypes.CHANGE_TODAY_ANALYSIS, {todayAnalysis})
export const changeTodayLoadingState = (isLoading: boolean) =>
  action(TodayActionTypes.CHANGE_LOADING_STATE, {isLoading})
