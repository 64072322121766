import * as CommonActions from './actions'
import {ActionType} from 'typesafe-actions'

export interface CommonState {
  readonly scrollY: number
  mobileMenuOpen: boolean
  recentListOpen: boolean
  error: boolean
  errorType: ErrorType
  errorMessage: string
  notification: boolean
  notificationMessage: string
  moveToAfterLoad: boolean
}

export enum CommonActionTypes {
  CHANGE_SCROLL_Y = '@@common/CHANGE_SCROLL_Y',
  CHANGE_MOBILE_MENU_OPEN = '@@common/CHANGE_MOBILE_MENU_OPEN',
  CHANGE_RECENT_LIST_OPEN = '@@common/CHANGE_RECENT_LIST_OPEN',
  CHANGE_ERROR = '@@common/CHANGE_ERROR',
  CHANGE_ERROR_TYPE = '@@common/CHANGE_ERROR_TYPE',
  CHANGE_ERROR_MESSAGE = '@@common/CHANGE_ERROR_MESSAGE',
  CLEAR_ERROR = '@@common/CLEAR_ERROR',

  CHANGE_NOTIFICATION = '@@common/CHANGE_NOTIFICATION',
  CHANGE_NOTIFICATION_MESSAGE = '@@common/CHANGE_NOTIFICATION_MESSAGE',
  CLEAR_NOTIFICATION = '@@common/CLEAR_NOTIFICATION',
  CHANGE_MOVE_TO_AFTER_LOAD = "@@common/CHANGE_MOVE_TO_AFTER_LOAD"
}

export type CommonAction = ActionType<typeof CommonActions>

export enum ErrorType {
  NONE = 'NONE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  NOT_ENOUGH_POINT = 'NOT_ENOUGH_POINT',
  DB_ERROR = 'DB_ERROR',
  INVALID_INPUT = 'INVALID_INPUT',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  NOT_AGREE_TERMS = 'NOT_AGREE_TERMS',
  ErrorType = 'NOT_SELECTED_SIJOO', // 만세력에서 시주가 선택되지 않았을 때.
  INVALID_PAYER = '입금자명이 올바르지 않습니다.',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}
