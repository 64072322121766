import {action} from 'typesafe-actions'
import {CommonActionTypes, ErrorType} from './types'

export const changeScrollY = (position: number) =>
  action(CommonActionTypes.CHANGE_SCROLL_Y, {position})
export const changeMobileMenuOpen = (open: boolean) =>
  action(CommonActionTypes.CHANGE_MOBILE_MENU_OPEN, {open})
export const changeRecentListOpen = (open: boolean) =>
  action(CommonActionTypes.CHANGE_RECENT_LIST_OPEN, {open})

export const changeError = (error: boolean) =>
  action(CommonActionTypes.CHANGE_ERROR, {error})
export const changeErrorType = (errorType: ErrorType) =>
  action(CommonActionTypes.CHANGE_ERROR_TYPE, {errorType})
export const changeErrorMessage = (errorMessage: string) =>
  action(CommonActionTypes.CHANGE_ERROR_MESSAGE, {errorMessage})
export const clearError = () => action(CommonActionTypes.CLEAR_ERROR)

export const changeNotification = (notification: boolean) =>
  action(CommonActionTypes.CHANGE_NOTIFICATION, {notification})
export const changeNotificationMessage = (notificationMessage: string) =>
  action(CommonActionTypes.CHANGE_NOTIFICATION_MESSAGE, {notificationMessage})
export const clearNotification = () =>
  action(CommonActionTypes.CLEAR_NOTIFICATION)

export const changeMoveToAfterLoad = (val: boolean) => action(CommonActionTypes.CHANGE_MOVE_TO_AFTER_LOAD, {val})