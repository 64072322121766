'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
// Generated by CoffeeScript 2.5.1
exports.default = {
  namespaces: {},
  on: function on(event, cb, opts) {
    var options
    if (this.namespaces == null) {
      this.namespaces = {}
    }
    this.namespaces[event] = cb
    options = opts || false
    this.addEventListener(event.split('.')[0], cb, options)
    return this
  },
  off: function off(event) {
    if (this.namespaces != null && this.namespaces[event] != null) {
      this.removeEventListener(event.split('.')[0], this.namespaces[event])
      delete this.namespaces[event]
    }
    return this
  },
  startEventBinding: function startEventBinding() {
    if (typeof window !== 'undefined' && window !== null) {
      window.on = this.on
      window.off = this.off
    }
    if (typeof document !== 'undefined' && document !== null) {
      document.on = this.on
      document.off = this.off
    }
    if (typeof Element !== 'undefined' && Element !== null) {
      Element.prototype.on = this.on
      return (Element.prototype.off = this.off)
    }
  },
}
