import Y1940 from './Y1940'
import Y1941 from './Y1941'
import Y1942 from './Y1942'
import Y1943 from './Y1943'
import Y1944 from './Y1944'
import Y1945 from './Y1945'
import Y1946 from './Y1946'
import Y1947 from './Y1947'
import Y1948 from './Y1948'
import Y1949 from './Y1949'

export const Y194 = {
  Y1940: Y1940,
  Y1941: Y1941,
  Y1942: Y1942,
  Y1943: Y1943,
  Y1944: Y1944,
  Y1945: Y1945,
  Y1946: Y1946,
  Y1947: Y1947,
  Y1948: Y1948,
  Y1949: Y1949,
}
