import React from 'react'
import {Action, AnyAction, applyMiddleware, combineReducers, createStore, Dispatch,} from 'redux'
import {all, fork} from 'redux-saga/effects'
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from 'redux-devtools-extension'
import {commonReducer} from './common/reducers'
import {ownerReducer} from './owner/reducers'
import todaySaga from './today/sagas'
import {todayReducer} from './today/reducers'
import {sajooReducer} from './sajoo/reducers'
import sajooSaga from './sajoo/sagas'
import paymentSaga from './payment/sagas'
import {paymentReducer} from './payment/reducers'
import {pickDayReducer} from './pick-day/reducers'
import pickDaySaga from './pick-day/sagas'
import {AppState} from './AppState'
import {recordsReducer} from "./records/reducers";
import recordsSaga from "./records/sagas";

const rootReducer = combineReducers({
  common: commonReducer,
  owner: ownerReducer,
  today: todayReducer,
  sajoo: sajooReducer,
  // login: loginReducer,
  records: recordsReducer,
  payment: paymentReducer,
  pickDay: pickDayReducer,
})

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>
}

function* rootSaga() {
  yield all([
    fork(todaySaga),
    fork(sajooSaga),
    // fork(loginSaga),
    fork(paymentSaga),
    fork(pickDaySaga),
    fork(recordsSaga),
  ])
}

//@ts-ignore
const makeStore: MakeStore<AppState> = (context: Context) => {
  const composeEnhancers =
    process.env.NODE_ENV === 'production'
      ? (x: any) => x
      : composeWithDevTools({})
  const sagaMiddleware = createSagaMiddleware()

  // browser라는 속성은 Process 타입에 없지만, Next.js 동작 중에 process 변수에 추가되게 된다.
  //@ts-ignore
  const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  )
  sagaMiddleware.run(rootSaga)
  return store
}

export default makeStore
