import Y1920 from './Y1920'
import Y1921 from './Y1921'
import Y1922 from './Y1922'
import Y1923 from './Y1923'
import Y1924 from './Y1924'
import Y1925 from './Y1925'
import Y1926 from './Y1926'
import Y1927 from './Y1927'
import Y1928 from './Y1928'
import Y1929 from './Y1929'

export const Y192 = {
  Y1920: Y1920,
  Y1921: Y1921,
  Y1922: Y1922,
  Y1923: Y1923,
  Y1924: Y1924,
  Y1925: Y1925,
  Y1926: Y1926,
  Y1927: Y1927,
  Y1928: Y1928,
  Y1929: Y1929,
}
