import {all, call, fork, put, takeLatest} from 'redux-saga/effects'

import {fetchSaJooRecord} from "./action";
import axios from "axios";
import {API_ENDPOINT} from "../../properies";
import {changeSaJooAnalysis, changeSaJooCode, changeSaJooLoadingState} from "../sajoo/actions";
import {changeError, changeErrorMessage, changeErrorType, changeMoveToAfterLoad} from "../common/actions";
import {navigate} from "gatsby"
import {RecordsActionTypes} from "./types";


function* handleFetchSaJooRecordAction(action: ReturnType<typeof fetchSaJooRecord>) {

  try {
    yield put(changeSaJooLoadingState(true))
    const res = yield call(
      axios.post,
      `${API_ENDPOINT}/sajoo/replay`,
      {code: action.payload.code}
      ,
    )
    const {code, analysis, due} = res.data
    yield put(changeSaJooAnalysis(analysis))
    yield put(changeSaJooCode(code))
    yield put(changeMoveToAfterLoad(true))
    yield navigate("/sajoo")




  } catch (e) {
    // console.log(e)
    const errorRes = e.response.data;
    yield put(changeError(true));
    yield put(changeErrorType(errorRes.code));
    yield put(changeErrorMessage(errorRes.message));
  } finally {
    yield put(changeSaJooLoadingState(false))

  }
}

function* watchFetchSaJooRecordAction() {
  yield takeLatest(
    RecordsActionTypes.FETCH_SAJOO_RECORD,
    handleFetchSaJooRecordAction,
  )
}


function* recordsSaga() {
  yield all([
    fork(watchFetchSaJooRecordAction),
  ])
}

export default recordsSaga;