import Y2070 from './Y2070'
import Y2071 from './Y2071'
import Y2072 from './Y2072'
import Y2073 from './Y2073'
import Y2074 from './Y2074'
import Y2075 from './Y2075'
import Y2076 from './Y2076'
import Y2077 from './Y2077'
import Y2078 from './Y2078'
import Y2079 from './Y2079'

export const Y207 = {
  Y2070: Y2070,
  Y2071: Y2071,
  Y2072: Y2072,
  Y2073: Y2073,
  Y2074: Y2074,
  Y2075: Y2075,
  Y2076: Y2076,
  Y2077: Y2077,
  Y2078: Y2078,
  Y2079: Y2079,
}
