import Y2090 from './Y2090'
import Y2091 from './Y2091'
import Y2092 from './Y2092'
import Y2093 from './Y2093'
import Y2094 from './Y2094'
import Y2095 from './Y2095'
import Y2096 from './Y2096'
import Y2097 from './Y2097'
import Y2098 from './Y2098'
import Y2099 from './Y2099'

export const Y209 = {
  Y2090: Y2090,
  Y2091: Y2091,
  Y2092: Y2092,
  Y2093: Y2093,
  Y2094: Y2094,
  Y2095: Y2095,
  Y2096: Y2096,
  Y2097: Y2097,
  Y2098: Y2098,
  Y2099: Y2099,
}
